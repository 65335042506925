import React, { useEffect} from "react";

import { useParams } from "react-router-dom";

import {getKlarnaOrder} from '../helper/request'




const Cart = () => {
  const {order_ids} = useParams();
const loadKlarnaOrder = async()=>{
    let htmlSnippet = await getKlarnaOrder(order_ids.split(','))
    
    setTimeout(()=>{
      
      var checkoutContainer = document.getElementById('my-checkout-container')
      checkoutContainer.innerHTML = htmlSnippet
      var scriptsTags = checkoutContainer.getElementsByTagName('script')
      // This is necessary otherwise the scripts tags are not going to be evaluated
      for (var i = 0; i < scriptsTags.length; i++) {
          var parentNode = scriptsTags[i].parentNode
          var newScriptTag = document.createElement('script')
          newScriptTag.type = 'text/javascript'
          newScriptTag.text = scriptsTags[i].text
          parentNode.removeChild(scriptsTags[i])
          parentNode.appendChild(newScriptTag)
      }

    })
  }
  
  

  useEffect(()=>{
    loadKlarnaOrder();
    
  },[])

  
  return (
    <div >
       <div id="my-checkout-container"></div>
    </div>
  );
};


export default Cart;
