const AUTH_LOCAL_STORAGE_KEY = "amzio_front";
const USER_LOCAL_STORAGE_KEY = "amzio_front_user";
const SETTINGS_STORAGE_KEY = "amzio_settings";
const LANGUAGE_STORAGE_KEY = "amzio_lang";

const getAuth = () => {
  if (!localStorage) {
    return false;
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return false;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config) => {
      const auth = getAuth();
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`;
      }
      config.headers.lang = getLanguage();

      return config;
    },
    (err) => Promise.reject(err)
  );
}

const setCurrentUser = (user) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(user);
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("USER LOCAL STORAGE SAVE ERROR", error);
  }
};
const getUser = () => {
  if (!localStorage) {
    return false;
  }

  const lsValue = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return false;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("USER LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAppSettings = (settings) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(settings);
    localStorage.setItem(SETTINGS_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("SETTINGS LOCAL STORAGE SAVE ERROR", error);
  }
};
const getAppSettings = () => {
  if (!localStorage) {
    return false;
  }

  const lsValue = localStorage.getItem(SETTINGS_STORAGE_KEY);
  if (!lsValue) {
    return false;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("SETTINGS STORAGE PARSE ERROR", error);
  }
};

const setLanguage = (lang) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
  } catch (error) {
    console.error("SETTINGS LOCAL STORAGE SAVE ERROR", error);
  }
};
const getLanguage = () => localStorage?.getItem(LANGUAGE_STORAGE_KEY) || "en";


export {
  getAuth,
  setAuth,
  removeAuth,
  setCurrentUser,
  getUser,
  setAppSettings,
  getAppSettings,
  setLanguage,
  getLanguage,
  AUTH_LOCAL_STORAGE_KEY,
  USER_LOCAL_STORAGE_KEY,
};
