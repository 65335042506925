import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const CATEGORY_LIST = `${API_URL}user/category-list`;
export const GET_PRODUCT_VIEW = `${API_URL}user/category-details`;

export const PREMIUM_CATEGORY_LIST = `${API_URL}user/premium-category-list`;
export const GET_PRODUCT = `${API_URL}user/product`;
export const LIST_PRODUCT = `${API_URL}user/products`;
export const LIST_PRODUCT_V2 = `${API_URL}user/productsList`;

export const GET_BANNERS = `${API_URL}user/home-banners`;
export const HOME_FILTER_LIST = `${API_URL}user/home-filters`;
export const RECOMMENDATION_LIST = `${API_URL}user/recommended`;
export const GET_FILTER_VIEW = `${API_URL}user/filter`;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}user/verify-token`;
export const LOGIN_URL = `${API_URL}user/login`;
export const REGISTER_URL = `${API_URL}user/create`; //not used yet
export const REQUEST_PASSWORD_URL = `${API_URL}user/forgot_password`; //not used yet

export const IMAGE_UPLOAD = `${API_URL}admin/image-upload`;

export const FILTER_LIST = `${API_URL}admin/filters-list`;

export const ADD_TO_CART = `${API_URL}user/add-to-cart`;
export const GET_CART = `${API_URL}user/get-cart`;
export const UPDATE_CART = `${API_URL}user/update-cart`;
export const REMOVE_CART = `${API_URL}user/remove-cart-item`;
export const COUNT_CART = `${API_URL}user/get-cart-item-count`;

export const VALIDATE_COUPON = `${API_URL}user/coupon-validate`;
export const GET_ADDRESEES = `${API_URL}user/addressess`;
export const PLACE_ORDER = `${API_URL}user/place-order`;

export const GET_ORDERS = `${API_URL}user/orders`;
export const GET_ORDER = `${API_URL}user/order`;
export const ADD_ADDRESS = `${API_URL}user/add-address`;
export const UPDATE_ADDRESS = `${API_URL}user/update-address`;
export const REMOVE_ADDRESS = `${API_URL}user/delete-address`;

export const POST_REVIEW = `${API_URL}user/post-product-review`;
export const PRODUCT_REVIEWS = `${API_URL}user/product-review`;

export const UPDATE_PROFILE = `${API_URL}user/profile`;
export const VERIFY_OTP = `${API_URL}user/verify-otp`;
export const FORGOT_PWD = `${API_URL}user/forgot-password`;

export const ORDER_PAID = `${API_URL}user/order-status-paid`;
export const STRIPE_CHARGE = `${API_URL}user/stripe-charge`;

export const MULTIPLE_ORDERS = `${API_URL}user/orders`;
export const GET_NOTIFICATIONS = `${API_URL}user/notifications`;

export const SUPPORT_REASONS = `${API_URL}user/support-reasons`;
export const SUPPORT_SUBMIT = `${API_URL}user/support-form`;

export const WISHLIST = `${API_URL}user/wishlist`;
export const ADD_WISHLIST = `${API_URL}user/wishlist`;
export const REMOVE_WISHLIST = `${API_URL}user/wishlist`;

export const DOWNLOAD_INVOICE = `${API_URL}user/download-invoice`;

export const APP_SETTINGS = `${API_URL}user/app-settings`;

export const KLARNA_ORDER = `${API_URL}user/generate-klarna-order`;

export function getCategory(parent_id) {
  return axios
    .post(CATEGORY_LIST, {
      parent_id,
    })
    .then((response) => {
      return response.data.data.records;
    });
}
export function getPremiumCategory() {
  return axios.post(PREMIUM_CATEGORY_LIST, {}).then((response) => {
    return response.data.data.records;
  });
}

// Server should return AuthModel
export function login(email, password) {
  return axios
    .post(LOGIN_URL, {
      email,
      password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

// Server should return AuthModel
export function register(
  email,
  firstname,
  lastname,
  password,
  password_confirmation
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

export function getUserByToken(token) {
  return axios
    .post(GET_USER_BY_ACCESSTOKEN_URL, {
      api_token: token,
    })
    .then((response) => {
      return response.data.data;
    });
}

const errorHandler = (error) => {
  if (error.response) {
    return error.response;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};
export function imageUpload(file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    method: "post",
    url: IMAGE_UPLOAD,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).catch((error) => {
    return errorHandler(error);
  });
}

export function getBanners() {
  return axios.get(GET_BANNERS).then((response) => {
    return response.data.data.records;
  });
}

export function getHomeFilters() {
  return axios.get(HOME_FILTER_LIST).then((response) => {
    return response.data.data.records;
  });
}

export function getRecommendations() {
  return axios.get(RECOMMENDATION_LIST).then((response) => {
    return response.data.data;
  });
}

export function getProducts(
  bbPage,
  search = "",
  parent_category_id = "",
  category_id = "",
  limit = 10,
  last_id = "",
  sort = "",
  min_price = 0,
  max_price = 0,
  dynamic_filters = [],
  filter_id = "",
  delivery_days = 0,
  vendor_user_id = "",
  selected_category,
  selected_parent_category,
  not_ids,
) {
  return axios
    .post(LIST_PRODUCT, {
      search: search,
      parent_category_id: parent_category_id,
      category_id: category_id,
      sort: sort,
      min_price: min_price,
      max_price: max_price,
      last_id: last_id,
      dynamic_filters: dynamic_filters,
      limit: limit,
      filter_id: filter_id,
      vendor_user_id: vendor_user_id,
      delivery_days: delivery_days,
      selected_category: selected_category,
      selected_parent_category: selected_parent_category,
      not_ids: not_ids,
      bbPage
    })
    .then((response) => {
      return response.data.data;
    });
}

export function getProductsV2(
  page,
  search = "",
  parent_category_id = "",
  category_id = "",
  limit = 10,
  last_id = "",
  sort = "",
  min_price = 0,
  max_price = 0,
  dynamic_filters = [],
  filter_id = "",
  delivery_days = 0,
  vendor_user_id = "",
  selected_category,
  selected_parent_category,
  not_ids,
) {
  return axios
    .post(LIST_PRODUCT_V2, {
      page: page,
      search: search,
      parent_category_id: parent_category_id,
      category_id: category_id,
      sort: sort,
      min_price: min_price,
      max_price: max_price,
      last_id: last_id,
      dynamic_filters: dynamic_filters,
      limit: limit,
      filter_id: filter_id,
      vendor_user_id: vendor_user_id,
      delivery_days: delivery_days,
      selected_category: selected_category,
      selected_parent_category: selected_parent_category,
      not_ids: not_ids,
    })
    .then((response) => {
      return response.data.data;
    });
}
export function getProduct(id) {
  return axios
    .get(GET_PRODUCT + "/" + id)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getCategoryDetails(id) {
  return axios
    .post(GET_PRODUCT_VIEW, { id: id })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
export function getFilterDetails(id) {
  return axios
    .post(GET_FILTER_VIEW, { id: id })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function addToCart(id, combinations) {
  return axios
    .post(ADD_TO_CART, { product_id: id, combinations: combinations })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getCart(coupon_code = "") {
  return axios
    .post(GET_CART, { coupon_code: coupon_code })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function updateCart(product_id, combinations, quantity) {
  return axios
    .post(UPDATE_CART, {
      product_id: product_id,
      combinations: combinations,
      quantity: quantity,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function removeCart(id) {
  return axios
    .delete(REMOVE_CART + "/" + id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function validateCoupon(coupon_code) {
  return axios
    .post(VALIDATE_COUPON, { coupon_code: coupon_code })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getAddresses() {
  return axios
    .get(GET_ADDRESEES)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getOrders(data) {
  return axios
    .get(GET_ORDERS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
export function getOrder(id) {
  return axios
    .get(GET_ORDER + "/" + id)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function placeOrder(
  coupon_code,
  gateway_type,
  shipping_address_id,
  billing_address_id
) {
  return axios
    .post(PLACE_ORDER, {
      coupon_code: coupon_code,
      gateway_type: gateway_type,
      shipping_address_id: shipping_address_id,
      billing_address_id: billing_address_id,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function addAddress(data) {
  return axios
    .post(ADD_ADDRESS, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function updateAddress(data) {
  return axios
    .patch(UPDATE_ADDRESS, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function postReview(data) {
  return axios
    .post(POST_REVIEW, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getProductReview(data) {
  return axios
    .post(PRODUCT_REVIEWS, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function updateProfile(data) {
  return axios
    .patch(UPDATE_PROFILE, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
export function getProfile() {
  return axios
    .get(UPDATE_PROFILE)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
export function verifyOtp(data) {
  return axios
    .post(VERIFY_OTP, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
export function forgotPwd(data) {
  return axios
    .post(FORGOT_PWD, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function orderPaid(data) {
  return axios
    .post(ORDER_PAID, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function stripeCharge(data) {
  return axios
    .post(STRIPE_CHARGE, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getMultipleOrders(data) {
  return axios
    .post(MULTIPLE_ORDERS, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getNotifications(data) {
  return axios
    .post(GET_NOTIFICATIONS, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getSupportReasons() {
  return axios
    .get(SUPPORT_REASONS)
    .then((response) => {
      return response.data.data.records;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function submitSupport(data) {
  return axios
    .post(SUPPORT_SUBMIT, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function addWishlist(product_id) {
  return axios
    .post(ADD_WISHLIST, {
      product_id: product_id,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function removeWishlist(product_id) {
  return axios
    .delete(REMOVE_WISHLIST + "/" + product_id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getWishlist() {
  return axios.get(WISHLIST).then((response) => {
    return response.data.data;
  });
}

export function removeAddress(id) {
  return axios
    .delete(REMOVE_ADDRESS + "/" + id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getInvoice(id) {
  return axios
    .get(DOWNLOAD_INVOICE + "/" + id, {
      responseType: "blob",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function appSettings() {
  return axios
    .get(APP_SETTINGS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getCartCount() {
  return axios
    .get(COUNT_CART)
    .then((response) => {
      return response.data.data.total;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}

export function getKlarnaOrder(id) {
  return axios
    .get(KLARNA_ORDER + "/" + id)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return errorHandler(error);
    });
}
