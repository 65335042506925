import { Container } from "react-bootstrap";

const DataProtection = () => {
  return (
    <Container className="pt-4">
      <h2 className="account-heading">Datenschutzerklärung</h2>
      <p>
        Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
        vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine
        Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe
        gemacht wird.
        <br />
        "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
      </p>

      <p>
        <h6>Server-Logfiles</h6>
        Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. <br />
        Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser
übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name der
aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider.
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.
      </p>

      <h5>Kontakt</h5>
      <p>
        <h6>Verantwortlicher</h6>
        Kontaktieren Sie uns auf Wunsch. Verantwortlicher für die Datenverarbeitung ist: Yahya Khan Noor, Carl-Goerdeler-Straße 3-
5, 60320 Frankfurt am Main Deutschland, 01724182490, infol@amzio.de
      </p>

      <p>
        <h6>Initiativ-Kontaktaufnahme des Kunden per E-Mail</h6>
        Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung
Ihrer Kontaktanfrage.
        <br />
        Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient
oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1
lit. b DSGVO.
        <br />
        Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage.
        <b>
          <i>
          In diesem Fall haben Sie das Recht,
aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
          </i>
        </b>
        <br />
        Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher
Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </p>

      <p>
        <h6>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</h6>
        Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von
Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme.
        <br />
        Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient
oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1
lit. b DSGVO.
        <br />
        Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage.
        <b>
          <i>
          In diesem Fall haben Sie das Recht,
aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
          </i>
        </b>
        <br />
        Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher
Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </p>

      <h2 className="account-heading"><i>Kundenkonto &emsp; Bestellungen</i></h2>

      <h6>Kundenkonto</h6>
      <p>
      Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegebenen Umfang. Die
Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die Verarbeitung
erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns
widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr
Kundenkonto wird anschließend gelöscht.
      </p>

      <h6>
      Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen
      </h6>
      <p>
      Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer
Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist für den Vertragsschluss erforderlich. Eine
Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit.
b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.
        <br />
        Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten Versandunternehmen und Dropshipping Anbieter,
Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und IT-Dienstleister. In allen Fällen beachten wir strikt die gesetzlichen
Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.
      </p>

      <h2 className="account-heading"><i>Bewertungen &emsp; Werbung</i></h2>

      <h6>
      Datenerhebung bei Verfassung eines Kommentars oder einer Bewertung
      </h6>
      <p>
      Bei der Kommentierung/Bewertung eines Artikels oder eines Beitrages erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
Kommentartext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Verarbeitung dient dem Zweck, eine Kommentierung/Bewertung
zu ermöglichen und Kommentare/Bewertungen anzuzeigen. <br />
Zum Zwecke der Verifikation Ihrer Bewertung/Ihres Kommentars erheben wir zudem folgende Daten: Bestellnummer .
Mit Absenden des Kommentars/Bewertung willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf
Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen,
ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre personenbezogenen
Daten werden anschließend gelöscht.
      </p>
      <p>
      Bei Veröffentlichung Ihres Kommentars/Ihrer Bewertung wird nur der von Ihnen angegebene Name veröffentlicht.
      </p>

      <h6>Shopauskunft-Kundenbewertung</h6>
      <p>
      Wir verwenden für unsere Website das Bewertungs-Tool “shopauskunft.de” der Händlerbund Management AG (Kohlgartenstraße 11 - 13,
04315 Leipzig; "Shopauskunft").
        <br />
        Nach Ihrer Bestellung möchten wir Sie bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren. Zu diesem Zweck werden Sie von uns
per E-Mail angeschrieben, wobei wir uns hierbei des technischen Systems "Rechtssichere Bewertungsanfrage (RBA)" bedienen. Dabei
verarbeiten wir die Daten zu Ihrer Bestellung (Bestellnummer/Rechnungsnummer, Einkaufswert und Versandkosten) sowie Ihre E-Mail-
Adresse. Gegebenenfalls verwenden wir diese Daten auch zum Zwecke der Verifikation Ihrer Bewertung.
        <br />
        Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs.1 lit. a DSGVO mit Ihrer Einwilligung, sofern Sie der Weitergabe Ihrer Daten und dem
Erhalt der Bewertungsaufforderung ausdrücklich zugestimmt haben.
        <br />
        Sie können die Einwilligung jederzeit unter Nutzung des entsprechenden Links in der E-Mail oder durch Mitteilung an uns widerrufen, ohne
dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
Weitere Informationen zum Datenschutz bei Verwendung von Shopauskunft finden Sie unter:&nbsp;
        <a href="https://www.shopauskunft.de/datenschutz">
        https://www.shopauskunft.de/datenschutz.
        </a>
      </p>

      <h6>Verwendung der E-Mail-Adresse für die Zusendung von Newslettern</h6>
      <p>
      Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschließlich für eigene Werbezwecke zum Newsletterversand,
sofern Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.
Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
Verarbeitung berührt wird. Sie können dazu den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch
Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.
      </p>

      <h2 className="account-heading"><i>Zahlungsdienstleister</i></h2>

      <h6>Verwendung von PayPal</h6>
      <p>
      Wir verwenden auf unserer Website den Zahlungsdienst PayPal der PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard Royal L-2449,
Luxemburg; "PayPal"). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu können. Mit
Auswahl und Nutzung von Zahlung via PayPal werden die zur Zahlungsabwicklung erforderlichen Daten an PayPal übermittelt, um den
Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
      </p>
      <p>
      Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung. Diese finden Sie
unter&nbsp;
        <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">
        https://www.paypal.com/de/webapps/mpp/ua/privacy-full
        </a>
      </p>

      <h6>Verwendung von PayPal Plus</h6>
      <p>
      Wir verwenden auf unserer Website den Zahlungsdienst PayPal Plus der PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard Royal L-
2449, Luxemburg; "PayPal"). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu können. Mit
Auswahl und Nutzung von Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal werden die zur Zahlungsabwicklung
erforderlichen Daten an PayPal übermittelt, um den Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung
erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
      </p>

      <p>
      Bei einzelnen Zahlungsarten wie Kreditkarte via PayPal, Lastschrift via PayPal behält sich PayPal das Recht vor, ggf. eine Bonitätsauskunft
auf der Basis mathematisch-statistischer Verfahren unter Nutzung von Auskunfteien einzuholen. Hierzu übermittelt PayPal die zu einer
Bonitätsprüfung benötigten personenbezogenen Daten an eine Auskunftei und verwendet die erhaltenen Informationen über die statistische
Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene Entscheidung über die Begründung, Durchführung oder Beendigung des
Vertragsverhältnisses. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich
anerkannter mathematisch-statistischer Verfahren berechnet werden und in deren Berechnung unter anderem Anschriftendaten einfließen.
Ihre schutzwürdigen Belange werden gemäß den gesetzlichen Bestimmungen berücksichtigt. Die Datenverarbeitung dient dem Zweck der
Bonitätsprüfung für eine Vertragsanbahnung. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem
überwiegenden berechtigten Interesse am Schutz vor Zahlungsausfall, wenn PayPal in Vorleistung geht.
        <b>
          <i>
          Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f
DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch Mitteilung an PayPal zu widersprechen.
          </i>
        </b>
        Die Bereitstellung der Daten ist für den Vertragsschluss mit der von Ihnen gewünschten Zahlart erforderlich. Eine Nichtbereitstellung hat zur
Folge, dass der Vertrag nicht mit der von Ihnen gewählten Zahlart geschlossen werden kann.
      </p>

      <h6>Verwendung von PayPal Express</h6>
      <p>
      Wir verwenden auf unserer Website den Zahlungsdienst PayPal Express der PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard Royal
L-2449, Luxemburg; "PayPal"). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst PayPal Express
anbieten zu können. Zur Einbindung dieses Zahlungsdienstes ist es erforderlich, dass PayPal beim Aufruf der Website Daten (z.B. IPAdresse,
Gerätetyp, Betriebssystem, Browsertyp, Standort Ihres Geräts) sammelt, speichert und analysiert. Hierzu können auch Cookies
eingesetzt werden. Die Cookies ermöglichen die Wiedererkennung Ihres Browsers.
        <br />
        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden
berechtigten Interesse an einem kundenorientierten Angebot von verschiedenen Zahlarten.
        <b>
          <i>
          Sie haben das Recht aus Gründen, die sich
aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitung Sie betreffender personenbezogener Daten zu
widersprechen.
          </i>
        </b>
        <br />
        Mit Auswahl und Nutzung von PayPal Express werden die zur Zahlungsabwicklung erforderlichen Daten an PayPal übermittelt, um den
Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
Nähere Informationen zur Datenverarbeitung bei Verwendung des Zahlungsdienstes PayPal Express finden Sie in der dazugehörigen
Datenschutzerklärung unter&nbsp;
        <a href="www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE#Updated_PS">
        www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE#Updated_PS.
        </a>
      </p>

      <h6>Verwendung von PayPal Check-Out</h6>
      <p>
      Wir verwenden auf unserer Website den Zahlungsdienst PayPal Check-Out der PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard
Royal L-2449, Luxemburg; "PayPal"). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu
können. Mit Auswahl und Nutzung von Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder „Später Bezahlen“ via PayPal
werden die zur Zahlungsabwicklung erforderlichen Daten an PayPal übermittelt, um den Vertrag mit Ihnen mit der gewählten Zahlart erfüllen
zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
      </p>
      <p>
        <u>
        Kreditkarte via PayPal, Lastschrift via PayPal & „Später bezahlen“ via PayPal
        </u>
        <br />
        Bei einzelnen Zahlungsarten wie Kreditkarte via PayPal, Lastschrift via PayPal oder „Später bezahlen“ via PayPal behält sich PayPal das
Recht vor, ggf. eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren unter Nutzung von Auskunfteien einzuholen.
Hierzu übermittelt PayPal die zu einer Bonitätsprüfung benötigten personenbezogenen Daten an eine Auskunftei und verwendet die
erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene Entscheidung über die
Begründung, Durchführung oder Beendigung des Vertragsverhältnisses. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-
Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet werden und in deren
Berechnung unter anderem Anschriftendaten einfließen. Ihre schutzwürdigen Belange werden gemäß den gesetzlichen Bestimmungen
berücksichtigt. Die Datenverarbeitung dient dem Zweck der Bonitätsprüfung für eine Vertragsanbahnung. Die Verarbeitung erfolgt auf
Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Schutz vor Zahlungsausfall, wenn PayPal
in Vorleistung geht.
        <br />
        <b><i>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f
DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch Mitteilung an PayPal zu widersprechen.</i></b> Die
Bereitstellung der Daten ist für den Vertragsschluss mit der von Ihnen gewünschten Zahlart erforderlich. Eine Nichtbereitstellung hat zur
Folge, dass der Vertrag nicht mit der von Ihnen gewählten Zahlart geschlossen werden kann.
      </p>
      <p>
        <u>Drittanbieter</u>
        <br />
        Bei Bezahlung über die Zahlungsart eines Drittanbieters werden die zur Zahlungsabwicklung erforderlichen Daten an PayPal übermittelt.
Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Zur Durchführung dieser Zahlungsart werden die Daten ggf.
sodann seitens PayPal an den jeweiligen Anbieter weitergegeben. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
Lokale Drittanbieter können beispielsweise sein:
        <ul>
          <li>
          Sofort (SOFORT GmbH, Theresienhöhe 12, 80339 München, Deutschland)
          </li>
          <li>
          giropay (Paydirekt GmbH, Stephanstr. 14-16, 60313 Frankfurt am Main)
          </li>
        </ul>
      </p>
      <p>
        <u>Rechnungskauf via PayPal</u>
        <br />
        Bei Bezahlung über die Zahlungsart Rechnungskauf werden die zur Zahlungsabwicklung erforderlichen Daten zunächst an PayPal
übermittelt. Zur Durchführung dieser Zahlungsart werden die Daten sodann seitens PayPal an die Ratepay GmbH (Franklinstraße 28-29,
10587 Berlin; "Ratepay") übermittelt um den Vertrag mit Ihnen mit der gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf
Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Ratepay führt ggf. eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren
(Wahrscheinlichkeits- bzw. Score - Werte) unter Nutzung von Auskunfteien durch nach bereits oben beschriebenen Ablauf. Die
Datenverarbeitung dient dem Zweck der Bonitätsprüfung für eine Vertragsanbahnung. Die Verarbeitung erfolgt auf Grundlage des Art. 6
Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Schutz vor Zahlungsausfall, wenn Ratepay in Vorleistung geht.
Weitere Informationen zum Datenschutz und welche Auskunfteien Ratpay verwenden finden Sie unter&nbsp;
        <a href="https://www.ratepay.com/legal-payment-dataprivacy/">
        https://www.ratepay.com/legal-payment-dataprivacy/
        </a>
        &nbsp;und&nbsp;
        <a href="https://www.ratepay.com/legal-payment-creditagencies/">
        https://www.ratepay.com/legal-payment-creditagencies/
        </a>
        .
      </p>
      <p>
      Nähere Informationen zur Datenverarbeitung bei Verwendung von PayPal finden Sie in der dazugehörigen Datenschutzerklärung unter&nbsp;
        <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">
        https://www.paypal.com/de/webapps/mpp/ua/privacy-full
        </a>
        .
      </p>

      <h6>Verwendung des Zahlungsdienstleisters Stripe</h6>
      <p>
      Wir verwenden auf unserer Website den Zahlungsdienst Stripe der Stripe Payments Europe Ltd., 1 Grand Canal Street Lower, Grand Canal
Dock, Dublin, Irland). Die Datenverarbeitung dient dem Zweck, Ihnen die Zahlung über den Zahlungsdienst anbieten zu können. Mit Auswahl
und Nutzung von Stripe werden die zur Zahlungsabwicklung erforderlichen Daten an Stripe übermittelt, um den Vertrag mit Ihnen mit der
gewählten Zahlart erfüllen zu können. Diese Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
        <br />
        Stripe behält sich das Recht vor, ggf. eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren unter Nutzung von
Auskunfteien einzuholen. Hierzu übermittelt Stripe die zu einer Bonitätsprüfung benötigten personenbezogenen Daten an eine Auskunftei
und verwendet die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene
Entscheidung über die Begründung, Durchführung oder Beendigung des Vertragsverhältnisses. Die Bonitätsauskunft kann
Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren
berechnet werden und in deren Berechnung unter anderem Anschriftendaten einfließen. Ihre schutzwürdigen Belange werden gemäß den
gesetzlichen Bestimmungen berücksichtigt. Die Datenverarbeitung dient dem Zweck der Bonitätsprüfung für eine Vertragsanbahnung. Die
Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Schutz vor
Zahlungsausfall, wenn Stripe in Vorleistung geht.
        <br />
        <b>
          <i>
          Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f
DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch Mitteilung an Stripe zu widersprechen.
          </i>
        </b>
        Die
Bereitstellung der Daten ist für den Vertragsschluss mit der von Ihnen gewünschten Zahlart erforderlich. Eine Nichtbereitstellung hat zur
Folge, dass der Vertrag nicht mit der von Ihnen gewählten Zahlart geschlossen werden kann.
<br />
Alle Stripe-Transaktionen unterliegen der Stripe-Datenschutzerklärung. Diese finden Sie unter&nbsp;
        <a href="https://stripe.com/de/privacy">
          https://stripe.com/de/privacy
        </a>
      </p>

      <h2><i>Cookies</i></h2>
      <p>
      Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des
Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers
beim erneuten Aufrufen der Website ermöglicht.
      </p>
      <p>
      Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl
entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und
über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits
gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche
Funktionen dieser Website vollumfänglich nutzen können.
      </p>
      <p>
      Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch
deaktivieren) können:
        <br /> Chrome:&nbsp;
        <a href="https://support.google.com/accounts/answer/61416?hl=de">
        https://support.google.com/accounts/answer/61416?hl=de
        </a>
        <br /> Microsoft Edge:&nbsp;
        <a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-lB6schen-63947406-40ac-c3b8-57b9-
2a946a29ae09">
        https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-lB6schen-63947406-40ac-c3b8-57b9-
2a946a29ae09
        </a>
        <br />
        Mozilla Firefox:&nbsp;
        <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
        https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
        </a>
        <br /> Safari:&nbsp;
        <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
        https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
        </a>
      </p>

      <h6>Technisch notwendige Cookies</h6>
      <p>
      Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen
Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies
unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer
Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach
einem Seitenwechsel wiedererkannt wird.
      </p>
      <p>
      Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 25 Abs. 2 TTDSG. Die Verarbeitung Ihrer
personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
Gewährleistung der optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.
        <b>
          <i>
          Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitung Sie
betreffender personenbezogener Daten zu widersprechen.
          </i>
        </b>
      </p>

      <h2>
        <i>Plug-ins und Sonstiges</i>
      </h2>
      <h6>Verwendung des Google Tag Managers</h6>
      <p>
      Wir verwenden auf unserer Website den Google Tag Manager der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland;
"Google"). <br />
Mit dieser Anwendung werden JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung insbesondere von Tracking- und
Analyse-Tools verwendet werden. Die Datenverarbeitung dient dem Zweck der bedarfsgerechten Gestaltung und der Optimierung unserer
Website. <br />
Der Google Tag Manager selbst speichert weder Cookies noch werden hierdurch personenbezogene Daten verarbeitet. Er ermöglicht
jedoch die Auslösung weiterer Tags, die personenbezogene Daten erheben und verarbeiten können.
Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie&nbsp;
        <a href="https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/">
          hier
        </a>
        .
      </p>

      <h6>Verwendung von GoogleMaps</h6>
      <p>
      Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google Ireland Limited (Gordon House,
Barrow Street, Dublin 4, Irland, "Google"). <br /> Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten. Dabei werden von Google
bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sind, auch Daten der Besucher der Websites erhoben, verarbeitet und
genutzt. <br /> Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission
vorhanden, das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet,
europäische Datenschutzgrundsätze einzuhalten. <br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m.
Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
Widerruf erfolgten Verarbeitung berührt wird. <br />
Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von Google unter&nbsp;
        <a href="https://www.google.com/privacypolicy.html">
          https://www.google.com/privacypolicy.html
        </a>
        . Dort haben Sie auch im Datenschutzcenter die Möglichkeit Ihre Einstellungen zu verändern, so
dass Sie Ihre von Google verarbeiteten Daten verwalten und schützen können.
      </p>

      <h6>Verwendung von Google Fonts</h6>
      <p>
      Wir verwenden auf unserer Website Google Fonts der Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; “Google”).
Die Datenverarbeitung dient dem Zweck der einheitlichen Darstellung von Schriftarten auf unserer Website. Um die Schriftarten zu laden,
wird beim Seitenaufruf eine Verbindung zu Servern von Google hergestellt. Hierbei können Cookies eingesetzt werden. Dabei wird u.a. Ihre
IP-Adresse sowie Informationen zu dem von Ihnen genutzten Browser verarbeitet und an Google übermittelt. Diese Daten werden nicht mit
Ihrem Google-Konto verknüpft. <br /> Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden,
das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische
Datenschutzgrundsätze einzuhalten.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m.
Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
Widerruf erfolgten Verarbeitung berührt wird. <br />
Nähere Informationen zur Datenverarbeitung und zum Datenschutz finden Sie unter&nbsp;
        <a href="https://www.google.de/intl/de/policies/">
        https://www.google.de/intl/de/policies/
        </a> sowie unter&nbsp;
        <a href="https://developers.google.com/fonts/faq">
        https://developers.google.com/fonts/faq.
        </a>
        .
      </p>

      <h6>Verwendung von Google Translate</h6>
      <p>
      Wir verwenden auf unserer Website über eine API-Einbindung den Übersetzungsdienst der
Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland).
 <br /> Die Datenverarbeitung dient dem Zweck die auf der Webseite zur Verfügung gestellten Informationen in anderen Sprache darzustellen.
Damit die Übersetzung nach Ihrer Wahl einer Landessprache automatisiert angezeigt wird, nimmt der von Ihnen verwendete Browser
Verbindung zu den Servern von Google auf. Hierbei können Cookies eingesetzt werden. Unter anderem können dabei folgende
Informationen erhoben und verarbeitet werden: IP-Adresse, URL der besuchten Seite, Datum und Uhrzeit. <br />
Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist ein Angemessenheitsbeschluss der EU-Kommission vorhanden,
das Trans-Atlantic Data Privacy Framework (TADPF). Google hat sich nach dem TADPF zertifiziert und damit verpflichtet, europäische
Datenschutzgrundsätze einzuhalten.<br />
Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 25 Abs. 1 S. 1 TTDSG i.V.m.
Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1
lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
Widerruf erfolgten Verarbeitung berührt wird. <br />
Nähere Informationen zur Erhebung und Nutzung Ihrer Daten durch Google finden Sie unter:&nbsp;
        <a href="https://www.google.com/policies/privacy/">
        https://www.google.com/policies/privacy/.
        </a>
        .
      </p>

      <h2>
        <i>Betroffenenrechte und Speicherdauer</i>
      </h2>

      <h6>Dauer der Speicherung</h6>
      <p>
      Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung
gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern
Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </p>

      <h6>Rechte der betroffenen Person</h6>
      <p>
      Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf
Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.
Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO
beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.
      </p>

      <h6>Beschwerderecht bei der Aufsichtsbehörde</h6>
      <p>
      Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die
Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
      </p>
      <p>
      Eine Beschwerde können Sie unter anderem bei der für uns zuständigen Aufsichtsbehörde einlegen, die Sie unter folgenden Kontaktdaten
erreichen:
      </p>
      <p>
      Hessischer Beauftragter für Datenschutz und Informationsfreiheit
        <br />
        Postfach 3163 <br />
        65021 Wiesbaden <br />
        Tel.: +49 611 14080 <br />
        Fax: +49 611 1408900 oder +49 611 1408901
        <br />
        E-Mail: poststelle@datenschutz.hessen.de
      </p>

      <h6>Widerspruchsrecht</h6>
      <p>
      Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit
Wirkung für die Zukunft zu widersprechen.
        <br /> Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige
Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der
Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
      </p>
      <p>letzte Aktualisierung: 13.07.2023</p>
    </Container>
  );
};

export default DataProtection;
