import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { Oval } from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import {
  addAddress,
  getAddresses,
  getCart,
  placeOrder,
  updateAddress,
  validateCoupon,
} from "../helper/request";
import { resHandler } from "../helper/response";

import { translate } from "../helper/translate";

import moment from "moment";
import { toast } from "react-toastify";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const Checkout = () => {
  const history = useHistory();

  const [coupon, setCoupon] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  //ToDo: Niharika unused variable
  const [applyCoupon, setApplyCoupon] = useState({
    code: "",
    amount: 0,
  });

  const [cartData, setCartData] = useState({});
  //ToDo: Niharika unused variable
  const [totalItems, setTotalItems] = useState(0);
  //ToDo: Niharika unused variable
  const [totalAmount, setTotalAmount] = useState(0);

  const [addresses, setAddresses] = useState([]);
  const [gateway, setGateway] = useState(1);

  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [sameAddress, setSameAddress] = useState(true);
  const loadCart = async () => {
    let items = 0;
    let amount = 0;
    //alert(coupon)
    let res = await getCart(coupon);
    if (res.data.data) {
      if (res.data.data.items) {
        for (let i in res.data.data.items) {
          items += res.data.data.items[i].quantity;
          amount +=
            res.data.data.items[i].sales_price *
            res.data.data.items[i].quantity;
        }

        setCartData(res.data.data);
        setTotalItems(items);
        setTotalAmount(amount);
      }
    }
  };

  //ToDo: Niharika unused variable
  const gotoProceed = () => {
    history.push("/checkout");
  };

  const validateCouponApply = async () => {
    let res = await validateCoupon(coupon);
    let output = resHandler(res);
    if (output.success) {
      setShowCoupon(false);
      loadCart();
    }
  };
  const [removeListner, setRemoveListner] = useState(false);
  const removeCoupon = async () => {
    setCoupon("");
    setRemoveListner(!removeListner);
  };

  const loadAddresses = async () => {
    let res = await getAddresses();
    if (res) {
      setAddresses(res);
      if (res.length) {
        setDeliveryAddress(res[0].id);
        setBillingAddress(res[0].id);
      }
    }
  };

  const [orederProcess, setOrderProcess] = useState(false);

  const orderPlace = async (e) => {
    e.preventDefault();
    if (addresses.length === 0) {
      toast.error("Please add an Delivery Address");
    } else {
      setOrderProcess(true);
      let res = await placeOrder(
        coupon,
        gateway,
        deliveryAddress,
        billingAddress
      );
      let output = resHandler(res);
      if (output.success) {
        let transaction_ids = [];
        let order_ids = [];
        for (let i in res.data.data.orders) {
          transaction_ids.push(res.data.data.orders[i].transaction_id);
          order_ids.push(res.data.data.orders[i].order_id);
        }
        if (gateway === 1) {
          window.location.href =
            process.env.REACT_APP_API_URL +
            "user/paypal-pay/" +
            order_ids.join(",");
          //history.push("/pay/stripe/"+order_ids.join(','));
        }

        else if (gateway == 2) {
          history.push("/pay/stripe/" + order_ids.join(','));
        }
        else if (gateway == 3) {
          history.push("/pay/klarna/" + order_ids.join(','));
        }


        //auto pay
        // await orderPaid({
        //   transaction_ids : transaction_ids
        // });
        // history.push("/orders");
      }
      setOrderProcess(false);
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  //ToDo: Niharika check what is wrong
  useEffect(() => {
    loadCart();
  }, [removeListner]);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const deafultAddress = {
    id: 0,
    name: "",
    type: 0,
    mobile: "",
    email: "",
    address_title: "",
    address: "",
    pincode: "",
    city: "",
    country: "",
    house_no: ""
  };
  const [addressRow, setAddressRow] = useState(deafultAddress);
  const addNewAddress = () => {
    setValidationData([]);
    setAddressRow(deafultAddress);
    setShowModal(true);
  };
  const handleChange = (e) => {
    let data = { ...addressRow };
    data[e.target.name] = e.target.value;
    setAddressRow(data);
  };
  const [validationData, setValidationData] = useState([]);
  const checkValidationError = (key, type) => {
    let res = false;
    for (let i in validationData) {
      if (key === validationData[i].param) {
        if (type === "msg") {
          res = validationData[i].msg;
        } else {
          res = true;
        }
      }
    }
    return res;
  };
  const [buttonloader, setButtonloader] = useState(false);
  const addAdressAction = async () => {
    setButtonloader(true);
    let data = { ...addressRow };
    let res = await addAddress(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadAddresses();
    setButtonloader(false);
  };

  const openEditModal = async (data) => {
    setValidationData([]);
    setAddressRow({ ...data });
    setShowModal(true);
  };
  const updateAddressAction = async () => {
    setButtonloader(true);
    let data = { ...addressRow };
    let res = await updateAddress(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadAddresses();
    setButtonloader(false);
  };

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      {/* shopping-cart */}
      {cartData.items ? (
        <section className="checkout-main-sec py-md-4 py-3">
          <Container>
            <div className="common-heading mb-md-4 mb-3">
              <h2>{translate("checkout_title")}</h2>
            </div>
            <Row>
              <Col lg={8}>
                <div>
                  <Form className="p-md-4 p-3  bg-white">
                    <div className="delivery-sec py-3">
                      <h2 className="checkout-heading mb-3">
                        {translate("choose_delivery_adrs")}
                        <a
                          onClick={addNewAddress}
                          style={{
                            float: "right",
                            fontSize: "initial",
                            color: "#4895ef",
                            cursor: "pointer",
                          }}
                        >
                          {translate("add_new")}
                        </a>
                      </h2>

                      <Row>
                        <Col lg={2} md={2} className="text-center mb-2">
                          <figure>
                            <img src="./assets/img/delivery-step.jpg" alt="" />
                          </figure>
                          <span className="steper">{translate("step")}:1</span>
                        </Col>

                        {addresses.map((value, index) => (
                          <Col lg={5} md={5} className="p-0" key={index}>
                            <div className="delivery-ad-radio">
                              <span className="custom_radio active">
                                {value.address_title}
                                <p>
                                  {value.address} , {value.pincode} ,{" "}
                                  {value.city} , {value.country}
                                </p>
                                <input
                                  id={`delAdd-${index}`}
                                  type="radio"
                                  className="checkout-input"
                                  name="check"
                                  checked={deliveryAddress === value.id}
                                  onClick={() => {
                                    setDeliveryAddress(value.id);
                                    if (sameAddress) {
                                      setBillingAddress(value.id);
                                    }
                                  }}
                                />
                                <span className="radio_indicator"></span>
                                <span className="checked_outer"></span>
                              </span>

                              <a
                                href="#"
                                onClick={() => openEditModal(value)}
                                className="pt-1 pe-3"
                              >
                                {translate("edit")}
                              </a>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>

                    <div className="delivery-sec py-3">
                      <h2 className="checkout-heading mb-3">
                        <span>{translate("choose_billing_adrs")}</span>
                        <div
                          style={{
                            float: "right",
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            id={`default-12`}
                            label={translate("same_as_delivery")}
                            checked={sameAddress}
                            onChange={() => {
                              if (!sameAddress) {
                                setBillingAddress(deliveryAddress);
                              }
                              setSameAddress(!sameAddress);
                            }}
                          />
                        </div>
                      </h2>

                      {!sameAddress ? (
                        <Row>
                          <Col
                            lg={2}
                            md={2}
                            className="text-center mb-2"
                            style={{ opacity: 0 }}
                          >
                            <figure>
                              <img
                                src="./assets/img/delivery-step.jpg"
                                alt=""
                              />
                            </figure>
                            <span className="steper">
                              {translate("step")}:1
                            </span>
                          </Col>

                          {addresses.map((value, index) => (
                            <Col lg={5} md={5} className="p-0" key={index}>
                              <div className="delivery-ad-radio">
                                <span className="custom_radio active">
                                  {value.address_title}
                                  <p>
                                    {value.address} , {value.pincode} ,{" "}
                                    {value.city} , {value.country}
                                  </p>
                                  <input
                                    id={`billingAdd-${index}`}
                                    type="radio"
                                    className="checkout-input"
                                    name="checkBel"
                                    checked={billingAddress === value.id}
                                    onClick={() => {
                                      setBillingAddress(value.id);
                                    }}
                                  />
                                  <span className="radio_indicator"></span>
                                  <span className="checked_outer"></span>
                                </span>

                                <a
                                  href="#"
                                  onClick={() => openEditModal(value)}
                                  className="pt-1 pe-3"
                                >
                                  {translate("edit")}
                                </a>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      ) : null}
                    </div>

                    <div className="delivery-sec select-payment-method py-3 mb-md-4 mb-2 ">
                      <h2 className="checkout-heading mb-3">
                        {translate("select_pay_method")}
                      </h2>
                      <Row className="align-items-center">
                        <Col lg={2} md={2} className="text-center mb-2">
                          <figure className="text-center">
                            <img src="./assets/img/mobile-payment.jpg" alt="" />
                          </figure>
                          <span className="steper">{translate("step")}:2</span>
                        </Col>

                        <Col lg={10} md={10}>
                          <Row>
                            <Col lg={6} sm={6}>
                              <div className="position-static">
                                <span className="custom_radio mb-0 active pt-0">
                                  &nbsp;{translate("pay_with_paypal")}
                                  <input
                                    type="radio"
                                    className="checkout-input start-0"
                                    name="pay"
                                    checked={gateway === 1}
                                    onClick={(e) => setGateway(1)}
                                  />
                                  <span className="radio_indicator start-0 top-0 mt-1"></span>
                                  <img
                                    src="./assets/img/paypal.jpg"
                                    alt=""
                                    className="mt-md-3 m-md-auto d-block mt-3"
                                  />
                                </span>
                              </div>
                            </Col>
                            <Col lg={6} sm={6}>
                              <div className="position-static">
                                <span className="custom_radio mb-0 active pt-0">
                                  &nbsp;{translate("pay_with_stripe")}
                                  <input
                                    type="radio"
                                    className="checkout-input start-0"
                                    name="pay"
                                    checked={gateway === 2}
                                    onClick={(e) => setGateway(2)}
                                  />
                                  <span className="radio_indicator start-0 top-0 mt-1"></span>
                                  <img
                                    src="./assets/img/stripe.jpg"
                                    alt=""
                                    className="mt-md-3 m-md-auto d-block mt-3"
                                  />
                                </span>
                              </div>
                            </Col>

                            <Col lg={4} sm={4}>
                              <div className="position-static">
                                <span class="custom_radio mb-0 active pt-0">
                                  &nbsp;Pay with Klarna
                                  <input
                                    type="radio"
                                    className="checkout-input start-0"
                                    name="pay"
                                    checked={gateway === 3}
                                    onClick={(e) => setGateway(3)}
                                  />
                                  <span className="radio_indicator start-0 top-0 mt-1"></span>
                                  <img
                                    src="./assets/img/kalarna.jpg"
                                    alt=""
                                    className="mt-md-3 m-md-auto d-block mt-3"
                                  />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <div className="delivery-sec item-delivery py-3">
                      <h2 className="checkout-heading mb-3">
                        {translate("items_and_delivery")}
                      </h2>
                      <Row>
                        <Col lg={3} md={3} className="text-center mb-2">
                          <figure className="text-center">
                            <img src="./assets/img/courier 1.jpg" alt="" />
                          </figure>
                          <span className="steper">{translate("step")}:3</span>
                        </Col>

                        <Col lg={9} md={9}>
                          <div className="orders-list checkout-order-list ms-md-5 ms-4">
                            {cartData?.items.map((value, index) => {
                              value.delivered_date = moment()
                                .add(parseInt(value.delivered_day), "days")
                                .format("LL");
                              return (
                                <ul
                                  className="m-0 list-unstyled pt-3 position-relative"
                                  key={index}
                                >
                                  {/* <div className="item-delivery-radio custom-radio">
                                        <span className="custom_radio mb-0 active">
                                          <input
                                            type="radio"
                                            className="checkout-input start-0"
                                          />
                                          <span className="radio_indicator start-0"></span>
                                        </span>
                                      </div> */}
                                  <li className="d-flex ">
                                    <figure
                                      className="m-0"
                                      style={{ display: "flex" }}
                                    >
                                      <img
                                        src={value.image}
                                        alt=""
                                        style={{ margin: "auto" }}
                                      />
                                    </figure>
                                    <figcaption>
                                      <h6>
                                        <Link to={`/product/${value.id}`}>
                                          {value.title}
                                        </Link>
                                      </h6>
                                      <span className="author-name">
                                        {translate("quantity")}{" "}
                                        {value?.quantity}
                                      </span>
                                      <span className="author-name">
                                        {translate("by")} {value?.store?.name}
                                      </span>
                                      <span className="delivery-date">
                                        {translate("delivered_on")}:{" "}
                                        {value.delivered_date}
                                      </span>
                                      {value.combinations.map((value) => (
                                        <span key={index}>
                                          {" "}
                                          {capitalizeFirstLetter(
                                            value.key
                                          )} : {value.title}
                                        </span>
                                      ))}
                                      <h2>
                                        <span>
                                          {translate("order_amount")}:
                                        </span>
                                        {appSettings.currency_symbol}
                                        {value.sales_price * value.quantity}
                                      </h2>
                                    </figcaption>
                                  </li>
                                  {/* <div className="order-all-info">
                                        <ul className="mb-3 p-0 order-info border-0">
                                          <li>Order# {value.order}</li>
                                          <li>Date Order Placed: 5 september 2017</li>
                                          <li>Ship to: Aubrey laos</li>
                                        </ul>
                                      </div> */}
                                </ul>
                              );
                            })}

                            <div className="checkout-total">
                              <ul className="m-0 p-0 border-0 mt-3">
                                <li className="border-0 m-0">
                                  <span>{translate("item")}:</span>
                                  <span>
                                    {appSettings.currency_symbol}
                                    {cartData.total_amount}
                                  </span>
                                </li>

                                {cartData.coupon_discount ? (
                                  <>
                                    <li className="border-0 m-0">
                                      <span>
                                        {translate("coupon_discount")} ({coupon}
                                        ):
                                      </span>
                                      <span>
                                        {appSettings.currency_symbol}
                                        {cartData.coupon_discount}
                                      </span>
                                    </li>
                                    <li className="border-0 m-0">
                                      <a
                                        className="btn btn-danger"
                                        onClick={removeCoupon}
                                        style={{
                                          fontWeight: 600,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {translate("remove_coupon")}
                                      </a>
                                    </li>
                                  </>
                                ) : (
                                  <li className="border-0 m-0">
                                    <a
                                      className="btn btn-primary"
                                      onClick={() => {
                                        let flag = !showCoupon;
                                        setShowCoupon(flag);
                                      }}
                                      style={{
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {translate("have_a_coupon")}?
                                    </a>

                                    {showCoupon ? (
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            position: "relative",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            style={{
                                              paddingLeft: "5px",
                                              minWidth: "200px",
                                            }}
                                            value={coupon}
                                            onChange={(e) => {
                                              setCoupon(e.target.value);
                                            }}
                                          />
                                          <a
                                            onClick={() => {
                                              setShowCoupon(false);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              position: "absolute",
                                              right: "7px",
                                              fontSize: "22px",
                                              lineHeight: "unset",
                                              opacity: ".7",
                                            }}
                                          >
                                            &times;
                                          </a>
                                        </div>
                                        <a
                                          onClick={validateCouponApply}
                                          className="btn btn-primary"
                                        >
                                          {translate("apply")}
                                        </a>
                                        &nbsp;
                                      </div>
                                    ) : null}
                                  </li>
                                )}
                                <li className="border-0 m-0">
                                  <span>{translate("delivery")}:</span>
                                  <span>
                                    {appSettings.currency_symbol}
                                    {cartData.delivery_charges}
                                  </span>
                                </li>
                              </ul>
                              <h2>
                                {translate("total_amount")}:{" "}
                                <span>
                                  {appSettings.currency_symbol}
                                  {cartData.net_amount}{" "}
                                </span>
                              </h2>
                            </div>

                            {orederProcess ? (
                              <span>{translate("loading")}...</span>
                            ) : (
                              <Button
                                onClick={orderPlace}
                                variant="primary"
                                type="submit"
                                className="light-btn green-bg ms-0 ms-auto d-block mt-4"
                              >
                                {translate("proced_to_pay")}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </Col>

              <Col lg={4}>
                <div className="product-rel-main">
                  <div className="product-related-sec">
                    <h2>{translate("order_summary")}</h2>

                    <ul className="m-0 p-0 list-unstyled">
                      {(cartData?.items).map((value, index) => {
                        value.delivered_date = moment()
                          .add(parseInt(value.delivered_day), "days")
                          .format("LL");
                        return (
                          <li className="d-flex" key={index}>
                            <figure className="m-0" style={{ display: "flex" }}>
                              <img
                                src={value.image}
                                alt=""
                                style={{ margin: "auto" }}
                              />
                            </figure>
                            <figcaption>
                              <h5>{value.title}</h5>
                              <h3 className="d-flex justify-content-between">
                                {appSettings.currency_symbol}
                                {value.sales_price}
                              </h3>
                            </figcaption>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="checkout-total">
                      <ul className="m-0 p-0">
                        <li className="border-0 m-0">
                          <span>{translate("item")}:</span>
                          <span>
                            {appSettings.currency_symbol}
                            {cartData.total_amount}
                          </span>
                        </li>
                        {cartData.coupon_discount ? (
                          <li className="border-0 m-0">
                            <span>{translate("coupon_discount")}:</span>
                            <span>
                              {appSettings.currency_symbol}
                              {cartData.coupon_discount}
                            </span>
                          </li>
                        ) : null}
                        <li className="border-0 m-0">
                          <span>{translate("delivery")}:</span>
                          <span>
                            {appSettings.currency_symbol}
                            {cartData.delivery_charges}
                          </span>
                        </li>
                      </ul>
                      <h2>
                        {translate("total_amount")}:{" "}
                        <span>
                          {appSettings.currency_symbol}
                          {cartData.net_amount}{" "}
                        </span>
                      </h2>
                    </div>
                  </div>

                  {orederProcess ? (
                    <span>{translate("loading")}...</span>
                  ) : (
                    <Button
                      onClick={orderPlace}
                      variant="primary"
                      type="submit"
                      className="light-btn green-bg ms-0 w-100 mt-4"
                    >
                      {translate("proced_to_pay")}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <span>{translate("loading")}..</span>
      )}
      {/* shopping-cart */}

      {/* add-address */}

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        className="comon-modal"
      >
        <Modal.Header closeButton>
          {addressRow.id === 0 ? (
            <Modal.Title className="h6">{translate("address_add")}</Modal.Title>
          ) : (
            <Modal.Title className="h6">
              {translate("address_edit")}
            </Modal.Title>
          )}
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address_title")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("address_title")}
                    onChange={(event) => handleChange(event)}
                    name="address_title"
                    type="text"
                    value={addressRow.address_title}
                    placeholder={translate("same_as_delivery")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address_title", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address_type")}</Form.Label>

                  <Form.Select
                    isInvalid={checkValidationError("type")}
                    onChange={(event) => handleChange(event)}
                    name="type"
                    value={addressRow.type}
                  >
                    <option value="0" defaultValue>
                      {translate("personal")}
                    </option>
                    <option value="1" defaultValue>
                      {translate("commercial")}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("type", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label> {translate("name")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("name")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.name}
                    type="text"
                    name="name"
                    placeholder={translate("enter_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("name", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("mobile_number")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("mobile")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.mobile}
                    name="mobile"
                    placeholder={translate("enter_mobile_number")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("mobile", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("email")}</Form.Label>
                  <Form.Control
                    type="email"
                    isInvalid={checkValidationError("email")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.email}
                    name="email"
                    placeholder={translate("enter_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("email", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("country")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("country")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.country}
                    name="country"
                    placeholder={translate("enter_country")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("country", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("city")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("city")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.city}
                    name="city"
                    placeholder={translate("enter_city")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("city", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("pincode")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("pincode")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.pincode}
                    name="pincode"
                    placeholder={translate("enter_pincode")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("pincode", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={checkValidationError("address")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.address}
                    name="address"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("house_no")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={checkValidationError("address")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.house_no}
                    name="house_no"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {!buttonloader ? (
              addressRow.id === 0 ? (
                <Button variant="secondary" onClick={() => addAdressAction()}>
                  {translate("add")}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => updateAddressAction()}
                >
                  {translate("update")}
                </Button>
              )
            ) : (
              <Button variant="outline-primary d-flex" size="sm">
                <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                {translate("loading")}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/* add-address */}
    </div>
  );
};

export default Checkout;
