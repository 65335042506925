import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { StarRating, StarRatingInput } from "react-star-rating-input";
import "../assets/css/react-rating.css";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import { getInvoice, getOrder, postReview } from "../helper/request";
import { resHandler } from "../helper/response";
import { translate } from "../helper/translate";

import moment from "moment";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const OrderDetails = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState({ items: [] });
  const loadOrder = async () => {
    let res = await getOrder(id);
    res.delivered_date = moment()
      .add(parseInt(res.items[0].delivered_day), "days")
      .format("LL");
    res.order_date = moment(res.created_date * 1000).format("LL");
    setOrderData(res);
  };

  useEffect(() => {
    loadOrder();
  }, []);

  const [showModal, setShowModal] = useState(false);
  //ToDo: Niharika unused variable
  const [viewData, setViewData] = useState([]);
  const handleClose = () => {
    setShowModal(false);
  };
  const openFeedback = (order_id, product_id) => {
    let data = { ...reviewRow };
    data.order_id = order_id;
    data.product_id = product_id;
    setReviewRow(data);

    setShowModal(true);
  };
  const [reviewRow, setReviewRow] = useState({
    rating: 0,
    comments: "",
    order_id: "",
    product_id: "",
  });

  const handleChange = (e, key = false) => {
    let data = { ...reviewRow };
    if (key) {
      data[key] = e;
    } else {
      data[e.target.name] = e.target.value;
    }

    setReviewRow(data);
  };
  const [validationData, setValidationData] = useState([]);
  const checkValidationError = (key, type) => {
    let res = false;
    for (let i in validationData) {
      if (key === validationData[i].param) {
        if (type === "msg") {
          res = validationData[i].msg;
        } else {
          res = true;
        }
      }
    }
    return res;
  };
  const [buttonloader, setButtonloader] = useState(false);
  const postNewReview = async () => {
    setButtonloader(true);
    let data = { ...reviewRow };
    let res = await postReview(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadOrder();
    setButtonloader(false);
  };

  const tracking_status = [
    {
      key: 0,
      value: "Payment Pending",
      title: "Payment Pending",
      body: "Your Order waiting for payment",
    },
    {
      key: 1,
      value: "Ordered",
      title: "Order Paid",
      body: "Your Order confirmed successfully",
    },
    {
      key: 2,
      value: "On the way",
      title: "Order On the way",
      body: "Your Order on the way.",
    },
    {
      key: 3,
      value: "Delivered",
      title: "Order delivered",
      body: "Your delivered successfully.",
    },
  ];
  const getTrackingStatus = (key, type) => {
    let title = "";
    tracking_status.map((value, index) => {
      if (value.key === key) {
        title = value[type];
      }
    });
    return title;
  };

  const downloadInvoice = async (invoice_id, order_no) => {
    setButtonloader(true);
    let res = await getInvoice(invoice_id);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${order_no}.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setButtonloader(false);
  };
  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      {/* order-bredcum */}

      <section className="py-3 order-breadcum">
        <Container>
          <Row>
            <Col md={10}>
              <div className="order-history-bredcum d-flex align-items-center">
                <div className="common-heading me-sm-3 me-2">
                  <h2 className="mt-1">
                    {translate("virw_order")} (#{orderData.order_no} )
                  </h2>
                  <hr />
                  <h5 className="mt-1">
                    {translate("order_status")} :{" "}
                    {getTrackingStatus(orderData.status, "title")}{" "}
                  </h5>
                  <h5 className="mt-1">
                    {translate("order_date")}: {orderData.order_date}
                  </h5>
                  {orderData.status ? (
                    <h5 className="mt-1 delivery-date">
                      {translate("delivered_on")}: {orderData.delivered_date}
                    </h5>
                  ) : null}
                  {orderData.tracking_number ? (
                    <h5 className="mt-1 delivery-date">
                      {translate("trancking_number")}:{" "}
                      {orderData.tracking_number}
                    </h5>
                  ) : null}
                </div>

                {/* <ul className="p-0 m-0 list-unstyled">
                  <li>
                    <a href="#" className="order">
                      Order
                    </a>
                  </li>
                  <li>
                    <a href="#">Order History</a>
                  </li>
                  <li>
                    <a href="#">Cancelled Order</a>
                  </li>
                </ul> */}
              </div>
            </Col>
            <Col
              md={2}
              style={{
                textAlign: "center",
                display: "flex",
              }}
            >
              <div style={{ margin: "auto" }}>
                {orderData.tracking_url ? (
                  <a
                    className="btn btn-primary"
                    href={orderData.tracking_url}
                    target="_blank"
                    style={{ background: "#017d99", borderColor: "#017d99" }}
                  >
                    {translate("track_package")}
                  </a>
                ) : null}
                 {
                orderData.sevdesk_invoice_id?
                      buttonloader?<span>Loading...</span>:
                      <a className="btn btn-primary" href="#" onClick={()=>{downloadInvoice(orderData.sevdesk_invoice_id,orderData.order_no)}} style={{background:'#017d99',borderColor:'#017d99'}}  >Download Invoice</a>
                  :null
              } 
              </div>
            </Col>
            {/* <Col lg={7}>
              <Form className="d-flex align-items-center search-order">
                <Form.Control type="text" placeholder="Search all Orders" />
                <div className="past-order">
                  <Button variant="primary" type="submit">
                    Search
                  </Button>

                  <Form.Select aria-label="Default select example">
                    <option>Past 6 months</option>
                    <option value="1">Past 4 months</option>
                    <option value="2">Past 2 months</option>
                    <option value="3">Past 3 months</option>
                  </Form.Select>
                </div>
              </Form>
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* order-bredcum */}

      {/* shopping-cart */}

      <section className="shoping-cart-sec order-info-sec py-sm-4 py-2">
        <Container>
          <Form>
            <div className="table-responsive">
              <Table>
                <tbody>
                  {orderData.items.map((value, index) => {
                    //value.delivered_date = moment().add(parseInt(value.items[0].delivered_day), 'days').format('LL')
                    //value.order_date = moment(value.created_date*1000).format('LL')
                    return (
                      <tr key={index}>
                        <td>
                          <ul className="p-0 m-0 list-unstyled">
                            <li className="position-relative mb-2">
                              <div className="orders-list">
                                <ul className="m-0 p-0 list-unstyled">
                                  <li className="d-flex">
                                    <figure
                                      className="m-0"
                                      style={{ display: "flex" }}
                                    >
                                      <img
                                        src={value.image}
                                        alt=""
                                        style={{ margin: "auto" }}
                                      />
                                    </figure>
                                    <figcaption>
                                      <h6>{value.title}</h6>
                                      <span className="author-name">
                                        {translate("quantity")} :{" "}
                                        {value.quantity}
                                      </span>
                                      <span className="author-name">
                                        {appSettings.currency_symbol}
                                        {value.sales_price}
                                      </span>
                                    </figcaption>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <div
                            className="order-all-info px-2"
                            style={{ display: "grid" }}
                          >
                            <h6>{translate("details")}</h6>
                            {value.combinations.map((valueA, indexA) => (
                              <span className="author-name" key={indexA}>
                                {valueA.key} : {valueA.title}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="align-middle">
                          {true ? ( //orderData.status
                            <ul className="m-0 p-0 list-unstyled track-package">
                              {!value.review_given ? (
                                <li>
                                  <a
                                    onClick={() =>
                                      openFeedback(
                                        orderData.id,
                                        value.product_id
                                      )
                                    }
                                    href="#"
                                  >
                                    {translate("prod_feedback")}
                                  </a>
                                </li>
                              ) : (
                                <>
                                  <li className="d-flex">
                                    {translate("rating")} :{" "}
                                    <StarRating
                                      value={value.product_review.rating}
                                    />
                                  </li>
                                  <li className="d-flex">
                                    {translate("comment")} :{" "}
                                    {value.product_review.comments}
                                  </li>
                                </>
                              )}
                            </ul>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={3}>
                      <h5 style={{ paddingLeft: "30px" }}>
                        {translate("total")} : {appSettings.currency_symbol}
                        {orderData.pay_amount}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        </Container>
      </section>

      {/* shopping-cart */}

      {/* history-sec */}

      {/* <section className="history-sec mb-5">
        <Container>
          <div className="common-heading mb-4">
            <h2 className="green-text">Product related to this item </h2>
          </div>
          <div className="bg-white">
          <Productlist className="mb-3" />
          </div>
        </Container>
      </section> */}

      {/* history-sec */}

      <Modal show={showModal} onHide={handleClose} className="comon-modal">
        <Modal.Header closeButton>
          <Modal.Title className="h6">{translate("prod_feedback")}</Modal.Title>
          :<Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <Form.Label>{translate("rating")} </Form.Label>
                  <div
                    style={{ transform: "scale(1.5)", transformOrigin: "left" }}
                  >
                    <StarRatingInput
                      size={5}
                      value={reviewRow.rating}
                      name="rating"
                      onChange={(event) => handleChange(event, "rating")}
                    />
                  </div>

                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("rating", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <br />

              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("comment")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={checkValidationError("comments")}
                    onChange={(event) => handleChange(event)}
                    value={reviewRow.address}
                    name="comments"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("comments", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {!buttonloader ? (
              <Button variant="secondary" onClick={() => postNewReview()}>
                {translate("post")}
              </Button>
            ) : (
              <Button variant="outline-primary d-flex" size="sm">
                <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                {translate("rating")}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderDetails;
