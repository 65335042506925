import React, { useContext, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";

import {
  Button,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { Oval } from "react-loader-spinner";
import {
  getAuth,
  getLanguage,
  getUser,
  removeAuth,
  setAppSettings,
  setAuth,
  setCurrentUser,
  setLanguage,
} from "../../helper/auth";
import {
  appSettings,
  forgotPwd,
  getCategory,
  getPremiumCategory,
  getUserByToken,
  login,
  register,
  verifyOtp,
} from "../../helper/request";
import { resHandler } from "../../helper/response";

import { UserContext } from "../../helper/contextApi";
import { translate } from "../../helper/translate";
import "./index.css";

let authData = getAuth();
let userData = authData ? getUser() : false;

const Header = () => {
  function submenuremove() {
    document.getElementById("OnscrollBody").classList.remove("subsubmenu");
  }

  const globalData = useContext(UserContext);
  let location = useLocation();

  useEffect(() => {
    globalData.load_cart();
  }, [location])
  const query = new URLSearchParams(location.search);
  const search_query = query.get("query") ? query.get("query") : "";
  const [auth, setMyAuth] = useState(authData);
  const [user, setUser] = useState(userData);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setLoginObj(defaultLoginObj);
    signUpClose();
    setVerifyShow(false);
    setShowPwd(false);
    setShow(true);
  };
  const defaultLoginObj = {
    email: "",
    password: "",
  };
  const [loginObj, setLoginObj] = useState(defaultLoginObj);
  const loginHandleChange = (e) => {
    let data = { ...loginObj };
    data[e.target.name] = e.target.value;
    setLoginObj(data);
  };
  const [buttonloader, setButtonloader] = useState(false);
  //ToDo: Niharika unused variable
  const [validationData, setValidationData] = useState([]);
  const loginSubmit = async (e) => {
    e.preventDefault();

    setButtonloader(true);
    let email = loginObj.email;
    let res = await login(loginObj.email, loginObj.password);
    let output = resHandler(res);
    if (!output.success) {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    } else {
      if (res.data.data.api_token) {
        await setAuth(res.data.data);
        setMyAuth(res.data.data);

        const loginuser = await getUserByToken(res.data.data.api_token);
        setCurrentUser(loginuser);
        setUser(loginuser);

        setLoginObj(defaultLoginObj);
        handleClose();
      }
      if (!res.data.data.email_verify) {
        openVerifyOtp(email);
      }
    }
    setButtonloader(false);
  };
  const logoutAction = () => {
    removeAuth();
    setMyAuth(false);
  };

  const defaultSignUpObj = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    accept_terms: false,
  };
  const [signUpObj, setSignUpObj] = useState(defaultSignUpObj);
  const [showSignUp, setShowSignUp] = useState(false);
  const signUpClose = () => setShowSignUp(false);
  const signUpShow = () => {
    setSignUpObj(defaultSignUpObj);
    handleClose();
    setShowPwd(false);
    setShowSignUp(true);
  };

  const signUpHandleChange = (e) => {
    let data = { ...signUpObj };
    data[e.target.name] = e.target.value;
    setSignUpObj(data);
  };
  const signUpSubmit = async (e) => {
    e.preventDefault();
    let email = signUpObj.email;
    setButtonloader(true);
    let res = await register(
      signUpObj.email,
      signUpObj.first_name,
      signUpObj.last_name,
      signUpObj.password,
      signUpObj.password
    );
    let output = resHandler(res);
    if (!output.success) {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    } else {
      openVerifyOtp(email);
    }
    setButtonloader(false);
  };

  const [categoryData, setCategoryData] = useState([]);
  const [premiumCategoryData, setPremiumCategoryData] = useState([]);
  const [parentCaregory, setParentCaregory] = useState([]);
  const loadCategory = async () => {
    let res = await getCategory();
    setCategoryData(res);
  };
  const loadPremiumCategory = async () => {
    let res = await getPremiumCategory();
    setPremiumCategoryData(res);
  };
  const loadChildCategory = async (index, parent_id) => {
    document.getElementById("OnscrollBody").classList.add("subsubmenu");
    let cloneData = [...categoryData];
    if (!categoryData[index].children) {
      cloneData[index]["children"] = await getCategory(parent_id);
    }
    setParentCaregory(cloneData[index]["children"]);
    setCategoryData(cloneData);
  };
  const loadAppSettings = async () => {
    let res = await appSettings();
    setAppSettings(res);
  };
  useEffect(() => {
    loadAppSettings();
    loadPremiumCategory();
    loadCategory();
  }, []);

  // const selectCategory = async(cat_id)=>{
  //   let res = await getCategory();
  //   setCategoryData(res);
  // }

  //ToDo: Niharika unused variable
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  //ToDo: Niharika unused variable
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [toggle, setToggle] = useState(false);

  const [search, setSearch] = useState(search_query);

  const [verifyShow, setVerifyShow] = useState(false);
  const [verifyOtpRow, setVerifyOtpRow] = useState({
    email: "",
    otp: "",
  });
  const openVerifyOtp = async (email) => {
    let data = { ...verifyOtpRow };
    data.email = email;
    data.otp = "";
    setVerifyOtpRow(data);
    hideAllModal();
    setVerifyShow(true);
  };
  const verifyOtpSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    let res = await verifyOtp(verifyOtpRow);
    let output = resHandler(res);
    if (!output.success) {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    } else {
      handleShow();
    }
    setButtonloader(false);
  };

  const [forgotShow, setForgotShow] = useState(false);
  const [forgotRow, setForgotRow] = useState({
    email: "",
  });
  const forgotSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    let res = await forgotPwd(forgotRow);
    let output = resHandler(res);
    if (!output.success) {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    } else {
      openVerifyOtp(forgotRow.email);
    }
    setButtonloader(false);
  };
  const hideAllModal = () => {
    handleClose();
    handleClose1();
    handleClose2();
    signUpClose();
    setForgotShow(false);
  };
  const openForgot = async () => {
    let data = { ...forgotRow };
    data.email = "";
    setForgotRow(data);
    hideAllModal();
    setForgotShow(true);
  };

  const [showPwd, setShowPwd] = useState(false);

  const [lang, setLang] = useState(getLanguage());

  return (
    <>
      <header>
        <div className="top-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <a href="/">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/logo-amizo.svg`}
                  alt="logo"
                />
              </a>

              <Form
                action={`${process.env.REACT_APP_BASE_URL}/search`}
                className={toggle ? "mobilesearch" : ""}
              >
                <div className="form-search d-flex position-relative">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/img/search-icon.svg`}
                    alt=""
                  />
                  <input
                    type="text"
                    name="query"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder={translate("search_products")}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  {/*
                  <NavDropdown title="All" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      Action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </div>
              </Form>
            </div>

            <div className="top-header-right d-flex align-items-center">
              {auth ? (
                <div className="d-flex top-cart align-items-center d-lg-block d-none">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="/cart" style={{ position: "relative" }}>
                        <span>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/assets/img/cart.svg`}
                            alt=""
                          />
                        </span>
                        {globalData.cart_count ? (
                          <span
                            style={{
                              position: "absolute",
                              backgroundColor: "#017d99",
                              right: "-17px",
                              top: "-10px",
                              borderRadius: "50%",
                              fontSize: "9px",
                              color: "#fff",
                              minWidth: "13px",
                              height: "auto",
                              textAlign: "center",
                            }}
                          >
                            {globalData.cart_count}
                          </span>
                        ) : null}
                        {translate("cart")}
                      </a>
                    </li>
                    <li>
                      <a href="/orders">
                        <span>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/assets/img/order.svg`}
                            alt=""
                          />
                        </span>
                        {translate("orders")}
                      </a>
                    </li>
                    <li>
                      <a href="/account">
                        <span>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/assets/img/user.svg`}
                            alt=""
                          />
                        </span>
                        {translate("accounts")}
                      </a>
                    </li>
                    <li>
                      <a href="/notifications">
                        <span>
                          <i
                            className="fa fa-bell"
                            aria-hidden="true"
                            style={{ fontSize: "initial" }}
                          ></i>
                        </span>
                        {translate("notifications")}
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className="top-profile" style={{ border: "none" }}>
                {auth ? (
                  <>
                    <figcaption>
                      <h6>
                        {translate("hello")} {user.first_name}
                      </h6>

                      <a href="#" onClick={logoutAction}>
                        <h6>{translate("logout")}</h6>
                      </a>
                    </figcaption>

                    <figure>
                      <img src={user.image_url} alt="" />
                    </figure>
                  </>
                ) : (
                  <div className="login-signup">
                    <span onClick={handleShow}> {translate("login")}</span> /{" "}
                    <span onClick={signUpShow}> {translate("signup")}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="main-header d-lg-flex justify-content-between align-items-center">
          <Navbar expand="lg">
            <Container fluid>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <NavDropdown
                  title={
                    <>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/assets/img/category-ico.svg`}
                        alt=""
                        className="me-2"
                      />
                      <span>{translate("all_category")}</span>
                    </>
                  }
                  id="basic-nav-dropdown"
                >
                  {/* <NavDropdown.Item href="#action/3.1">Watches</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Electronics
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Mobiles
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.4">
                   Men's Fashion
                  </NavDropdown.Item> */}

                  <div className="sidebar sidepanel animate slideIn">
                    {/* second_screen */}

                    <div className="second_screen">
                      <div className="sidebar_sub_category">
                        <ul className="p-0 m-0">
                          {categoryData.map((value, index) => (
                            <li key={index}>
                              <Link
                                to="#"
                                onClick={() =>
                                  loadChildCategory(index, value.id)
                                }
                              >
                                {value.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {/* second_screen */}

                    {/* third_screen */}

                    <div className="second_screen third_screen">
                      <div className="screen_head">
                        <h4 className="cursor-pointer" onClick={submenuremove}>
                          <span>
                            <i className="fas fa-chevron-left"></i>
                          </span>
                          <img
                            alt=""
                            src={`${process.env.REACT_APP_BASE_URL}/assets/img/sidebar_icon1.png`}
                            className="hover"
                          />{" "}
                          {translate("sub_category")}
                        </h4>
                      </div>

                      <div className="sidebar_sub_category">
                        <ul className="p-0 m-0">
                          {parentCaregory.map((value, index) => (
                            <li key={index}>
                              <Link
                                onClick={() => {
                                  submenuremove();
                                  document
                                    .getElementById("basic-nav-dropdown")
                                    .click();
                                }}
                                to={`/search/${value.parent_id}/${value.id}`}
                                className="noicon"
                              >
                                {value.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {/* third_screen */}

                    {/* newmenu */}
                  </div>
                </NavDropdown>
                <Nav className="me-auto">
                  {premiumCategoryData.map((value, index) => {
                    let red_url = "/search/";
                    if (value.parent_id) {
                      red_url += value.parent_id;
                      red_url += "/";
                    }
                    red_url += value.id;
                    return (
                      <Link className="nav-link" to={red_url} key={index}>
                        {value.title}{" "}
                      </Link>
                    );
                  })}

                  {/* <Nav.Link href="#link">Buy again</Nav.Link>
                  <Nav.Link href="#home">Browsing History</Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div className="d-flex align-items-center for-mobile-header">
            <div className="mobile-cart-sec d-lg-none d-block">
              <div className="d-flex top-cart align-items-center ">
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      href="#"
                      className="d-block d-md-none"
                      onClick={() => setToggle((prev) => !prev)}
                    >
                      <span>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/assets/img/search1.svg`}
                          alt=""
                        />
                      </span>
                    </a>
                  </li>
                  {auth ? (
                    <>
                      <li>
                        <a href="/cart">
                          <span>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/cart.svg`}
                              alt=""
                            />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="/orders">
                          <span>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/order.svg`}
                              alt=""
                            />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="/account">
                          <span>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/user.svg`}
                              alt=""
                            />
                          </span>
                        </a>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
            </div>

            <div>
              <NavDropdown
                title={
                  <div className="header-flag-options">
                    <ReactCountryFlag
                      countryCode={lang === "en" ? "gb" : "de"}
                      svg
                      className="flag-square"
                    />
                    <span>{lang.toUpperCase()}</span>
                  </div>
                }
                className="language-selector"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setLang("en");
                    setLanguage("en");
                    window.location.reload();
                  }}
                >
                  <ReactCountryFlag
                    countryCode="gb"
                    svg
                    style={{
                      width: "1em",
                      height: "1em",
                    }}
                  />{" "}
                  EN
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setLang("de");
                    setLanguage("de");
                    window.location.reload();
                  }}
                >
                  <ReactCountryFlag
                    countryCode="de"
                    svg
                    style={{
                      width: "1em",
                      height: "1em",
                    }}
                  />{" "}
                  DE
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </header>

      <div>
        <Modal show={show} onHide={handleClose} className="comon-modal">
          <Modal.Header closeButton>
            <Modal.Title>{translate("signin_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>{translate("signin_descriptions")}</span>
            </div>

            <Form onSubmit={loginSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{translate("email")}</Form.Label>
                <Form.Control
                  type="email"
                  value={loginObj.email}
                  name="email"
                  onChange={(event) => loginHandleChange(event)}
                  placeholder={translate("enter_email")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{translate("password")}</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPwd ? "text" : "password"}
                    value={loginObj.password}
                    name="password"
                    onChange={(event) => loginHandleChange(event)}
                    placeholder={translate("password")}
                    required
                  />
                  {showPwd ? (
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPwd(!showPwd)}
                      className="password_show_btn fas fa-eye-slash"
                    ></i>
                  ) : (
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPwd(!showPwd)}
                      className="password_show_btn fas fa-eye"
                    ></i>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3 d-flex justify-content-between forget-password"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label={translate("remember_me")}
                  style={{ opacity: 0 }}
                />
                <a href="#" onClick={openForgot}>
                  {translate("forgot_password")}
                </a>
              </Form.Group>

              {!buttonloader ? (
                <Button variant="primary" type="submit">
                  {translate("signin")}
                </Button>
              ) : (
                <Button variant="outline-primary d-flex" size="sm">
                  <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                  {translate("loading")}
                </Button>
              )}

              <span className="d-block text-center dont-account">
                {" "}
                {translate("dont_hav_account")}?{" "}
                <a onClick={signUpShow}>{translate("signup")}</a>
              </span>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showSignUp} onHide={signUpClose} className="comon-modal">
          <Modal.Header closeButton>
            <Modal.Title>{translate("signup_title")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>{translate("signup_descrriptions")}</span>
            </div>

            <Form onSubmit={signUpSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{translate("first_name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  onChange={(event) => signUpHandleChange(event)}
                  value={signUpObj.first_name}
                  placeholder={translate("enter_first_name")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{translate("last_name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  onChange={(event) => signUpHandleChange(event)}
                  value={signUpObj.last_name}
                  placeholder={translate("enter_last_name")}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{translate("email")}</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(event) => signUpHandleChange(event)}
                  value={signUpObj.email}
                  placeholder={translate("enter_email")}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{translate("password")}</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPwd ? "text" : "password"}
                    name="password"
                    onChange={(event) => signUpHandleChange(event)}
                    value={signUpObj.password}
                    placeholder={translate("password")}
                    required
                  />
                  {showPwd ? (
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPwd(!showPwd)}
                      className="password_show_btn fas fa-eye-slash"
                    ></i>
                  ) : (
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPwd(!showPwd)}
                      className="password_show_btn fas fa-eye"
                    ></i>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3 d-flex justify-content-between forget-password"
                controlId="formBasicCheckbox"
              >
                {/* <Form.Check type="checkbox"  name="accept_terms" onChange={event=>signUpHandleChange(event)} value={signUpObj.accept_terms} label="Remember Me"  required/> */}
              </Form.Group>
              {!buttonloader ? (
                <Button variant="primary" type="submit">
                  {translate("signup")}
                </Button>
              ) : (
                <Button variant="outline-primary d-flex" size="sm">
                  <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                  {translate("loading")}
                </Button>
              )}
              <span className="d-block text-center dont-account">
                {" "}
                {translate("already_hav_account")}?{" "}
                <a onClick={handleShow}>{translate("login")}</a>
              </span>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={verifyShow}
          onHide={() => setVerifyShow(false)}
          className="comon-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{translate("forgot_title")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>{translate("forgot_descriptions")}</span>
            </div>

            <Form onSubmit={verifyOtpSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  name="otp"
                  onChange={(event) => {
                    let data = { ...verifyOtpRow };
                    data.otp = event.target.value;
                    setVerifyOtpRow(data);
                  }}
                  value={verifyOtpRow.otp}
                  placeholder={translate("enter_otp")}
                  required
                />
              </Form.Group>

              {!buttonloader ? (
                <Button variant="primary" type="submit">
                  {translate("verify_otp")}
                </Button>
              ) : (
                <Button variant="outline-primary d-flex" size="sm">
                  <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                  {translate("loading")}
                </Button>
              )}
              <span className="d-block text-center dont-account">
                {" "}
                {translate("already_hav_account")}?{" "}
                <a onClick={handleShow}>{translate("login")}</a>
              </span>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={forgotShow}
          onHide={() => setForgotShow(false)}
          className="comon-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{translate("change_pwd_title")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>{translate("change_pwd_descriptions")}</span>
            </div>

            <Form onSubmit={forgotSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(event) => {
                    let data = { ...forgotRow };
                    data.email = event.target.value;
                    setForgotRow(data);
                  }}
                  value={forgotRow.email}
                  placeholder={translate("enter_email")}
                  required
                />
              </Form.Group>

              {!buttonloader ? (
                <Button variant="primary" type="submit">
                  {translate("send_otp")}
                </Button>
              ) : (
                <Button variant="outline-primary d-flex" size="sm">
                  <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                  {translate("loading")}
                </Button>
              )}
              <span className="d-block text-center dont-account">
                {" "}
                {translate("already_hav_account")}?{" "}
                <a onClick={handleShow}>{translate("login")}</a>
              </span>
            </Form>
          </Modal.Body>
        </Modal>

        {/* change-address */}
      </div>
    </>
  );
};
export default Header;
