import { Container } from "react-bootstrap";

const TermsAndCondition = () => {
  return (
    <Container className="pt-4">
      <h2 className="account-heading">
      Allgemeine Geschäftsbedingungen und Kundeninformationen
      </h2>
      <h6>I. Allgemeine Geschäftsbedingungen</h6>
      <h6>§ 1 Grundlegende Bestimmungen</h6>
      <p>
        <b>(1)</b> Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (Yahya Khan Noor) über die Internetseite
www.amzio.de schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener
Bedingungen widersprochen.
      </p>

      <p>
        <b>(2)</b> Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die
überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede
natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer
selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
      </p>

      <h6>§ 2 Zustandekommen des Vertrages</h6>
      <p>
        <b>(1)</b> Gegenstand des Vertrages ist der Verkauf von Waren .
      </p>

      <p>
        <b>(2)</b> Bereits mit dem Einstellen des jeweiligen Produkts auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum
Abschluss eines Vertrages über das Online-Warenkorbsystem zu den in der Artikelbeschreibung angegebenen Bedingungen.
      </p>

      <p>
        <b>(3)</b> Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:
Die zum Kauf beabsichtigten Waren werden im "Warenkorb" abgelegt. Über die entsprechende Schaltfläche in der Navigationsleiste
können Sie den "Warenkorb" aufrufen und dort jederzeit Änderungen vornehmen.<br />
Nach Anklicken der Schaltfläche "Kasse" oder "Weiter zur Bestellung" (oder ähnliche Bezeichnung) und der Eingabe der persönlichen
Daten sowie der Zahlungs- und Versandbedingungen werden Ihnen abschließend die Bestelldaten als Bestellübersicht angezeigt.
      </p>
      <p>
      Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal (Express/Plus/Checkout), Amazon Pay, Sofort, giropay) nutzen, werden Sie
entweder auf die Bestellübersichtsseite in unserem Online-Shop geführt oder auf die Internetseite des Anbieters des Sofortzahl-Systems
weitergeleitet. <br /> Erfolgt eine Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw. Eingabe Ihrer Daten vor.
Abschließend werden Ihnen auf der Internetseite des Anbieters des Sofortzahl-Systems oder nachdem Sie zurück in unseren Online-Shop
geleitet wurden, die Bestelldaten als Bestellübersicht angezeigt.
      </p>
      <p>
      Vor Absenden der Bestellung haben Sie die Möglichkeit, die Angaben in der Bestellübersicht nochmals zu überprüfen, zu ändern (auch
über die Funktion "zurück" des Internetbrowsers) bzw. die Bestellung abzubrechen.
        <br />
        Mit dem Absenden der Bestellung über die entsprechende Schaltfläche ("zahlungspflichtig bestellen", "kaufen" / "jetzt kaufen",
"kostenpflichtig bestellen", "bezahlen" / "jetzt bezahlen" oder ähnliche Bezeichnung) erklären Sie rechtsverbindlich die Annahme des
Angebotes, wodurch der Vertrag zustande kommt.
      </p>

      <p>
        <b>(4)</b> Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt
per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist,
der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.
      </p>

      <h6>§ 3 Besondere Vereinbarungen zu angebotenen Zahlungsarten</h6>
      <p>
        <b>(1) Zahlung über "PayPal" / "PayPal Checkout"</b> <br />
        Bei Auswahl einer Zahlungsart, die über "PayPal" / "PayPal Checkout" angeboten wird, erfolgt die Zahlungsabwicklung über den
Zahlungsdienstleister PayPal (Europe) S.à.r.l. et Cie, S.C.A. (22-24 Boulevard Royal L-2449, Luxemburg; "PayPal"). Die einzelnen
Zahlungsarten über "PayPal" werden Ihnen unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz sowie im
Online-Bestellvorgang angezeigt. Für die Zahlungsabwicklung kann sich "PayPal" weiterer Zahlungsdienste bedienen; soweit hierfür
besondere Zahlungsbedingungen gelten, werden Sie auf diese gesondert hingewiesen. Nähere Informationen zu "PayPal" finden Sie unter&nbsp;
        <a href="https://www.paypal.com/de/webapps/mpp/ua/legalhub-full">
        https://www.paypal.com/de/webapps/mpp/ua/legalhub-full.
        </a>
      </p>

      <p>
        <b>(2) Zahlung über "Stripe"</b> <br />
        Bei Auswahl einer Zahlungsart, die über "Stripe" angeboten wird, erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister Stripe
Payments Europe Limited (1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02 H210, Ireland; "Stripe"). Die einzelnen
Zahlungsarten über "Stripe" werden Ihnen unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz sowie im
Online-Bestellvorgang angezeigt. Für die Zahlungsabwicklung kann sich "Stripe" weiterer Zahlungsdienste bedienen; soweit hierfür
besondere Zahlungsbedingungen gelten, werden Sie auf diese gesondert hingewiesen. Nähere Informationen zu "Stripe" finden Sie unter&nbsp;
        <a href="https://stripe.com/de">https://stripe.com/de.</a>
      </p>

      <h6>§ 4 Zurückbehaltungsrecht, Eigentumsvorbehalt</h6>
      <p>
        <b>(1)</b> Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.
      </p>
      <p>
        <b>(2)</b> Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.
      </p>

      <h6>§ 5 Gewährleistung</h6>
      <p>
        <b>(1)</b> Es bestehen die gesetzlichen Mängelhaftungsrechte.
      </p>
      <p>
        <b>(2)</b> Als Verbraucher werden Sie gebeten, die Ware bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und
Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht nach,
hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
      </p>
      <p>
        <b>(3)</b> Soweit ein Merkmal der Ware von den objektiven Anforderungen abweicht, gilt die Abweichung nur dann als vereinbart, wenn Sie vor
Abgabe der Vertragserklärung durch uns über selbige in Kenntnis gesetzt wurden und die Abweichung ausdrücklich und gesondert
zwischen den Vertragsparteien vereinbart wurde.
      </p>

      <h6>§ 6 Rechtswahl</h6>
      <p>
        <b>(1)</b> Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts
des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).
      </p>
      <p>
        <b>(2)</b> Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.
      </p>

      <hr />

      <h6>II. Kundeninformationen</h6>
      <h6>1. Identität des Verkäufers</h6>
      <p>
      Yahya Khan Noor <br />
      Carl-Goerdeler-Straße 3-5 <br />
      60320 Frankfurt am Main <br />
      Deutschland <br />
      Telefon: +491724182490 <br />
      E-Mail: info@amzio.de <br />
      </p>

      <p>
        <b>Alternative Streitbeilegung:</b> <br />
        Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter&nbsp;
        <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr.</a>
      </p>
      <p>
      Wir sind nicht bereit und nicht verpflichtet, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.
      </p>

      <h6>2. Informationen zum Zustandekommen des Vertrages</h6>
      <p>
      Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach Maßgabe der
Regelungen "Zustandekommen des Vertrages" unserer Allgemeinen Geschäftsbedingungen (Teil I.).
      </p>

      <h6>3. Vertragssprache, Vertragstextspeicherung</h6>
      <p>3.1. Vertragssprache ist deutsch .</p>

      <p>
        3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung über das Online - Warenkorbsystem
können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der
Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen
Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.
      </p>

      <h6>4. Wesentliche Merkmale der Ware oder Dienstleistung</h6>
      <p>
      Die wesentlichen Merkmale der Ware und/oder Dienstleistung finden sich im jeweiligen Angebot.
      </p>

      <h6>5. Preise und Zahlungsmodalitäten</h6>
      <p>
        5.1. Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten stellen Gesamtpreise dar. Sie beinhalten alle
Preisbestandteile einschließlich aller anfallenden Steuern.
      </p>
      <p>
        5.2. Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten. Sie sind über eine entsprechend bezeichnete Schaltfläche auf
unserer Internetpräsenz oder im jeweiligen Angebot aufrufbar, werden im Laufe des Bestellvorganges gesondert ausgewiesen und sind von
Ihnen zusätzlich zu tragen, soweit nicht die versandkostenfreie Lieferung zugesagt ist.
      </p>
      <p>
        5.3. Die Ihnen zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche auf unserer
Internetpräsenz oder im jeweiligen Angebot ausgewiesen.
      </p>
      <p>
        5.4. Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort
zur Zahlung fällig.
      </p>

      <h6>6. Lieferbedingungen</h6>
      <p>
        6.1. Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen finden sich unter einer
entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot.
      </p>

      <p>
        6.2. Soweit Sie Verbraucher sind, ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung
der verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig davon, ob die Versendung
versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein nicht vom Unternehmer benanntes Transportunternehmen
oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt haben.
      </p>

      <h6>7. Gesetzliches Mängelhaftungsrecht</h6>
      <p>
      Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Geschäftsbedingungen (Teil I).
      </p>
      <p>
      Diese AGB und Kundeninformationen wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes erstellt und werden
permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die Rechtssicherheit der Texte und haftet im
Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter:&nbsp;
        <a href="https://www.haendlerbund.de/de/leistungen/rechtssicherheit/agbservice">
        https://www.haendlerbund.de/de/leistungen/rechtssicherheit/agbservice
        </a>
        .
      </p>

      <p>letzte Aktualisierung: 29.11.2022</p>
    </Container>
  );
};

export default TermsAndCondition;
