import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import CartButton from "../components/CartButton";
import Productlist from "../components/Productlist";

import { Oval } from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";

import { StarRating } from "react-star-rating-input";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import {
  addWishlist,
  getProduct,
  getProductReview,
  getProducts,
  removeWishlist,
} from "../helper/request";
import { percentMech, resHandler } from "../helper/response";

import moment from "moment";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

// const images = [
//   {
//     original: "../assets/img/big-gallery.jpg",
//     thumbnail: "../assets/img/t-gallery1.jpg",
//     showFullscreenButton: "false",
//   },
//   {
//     original: "../assets/img/big-gallery.jpg",
//     thumbnail: "../assets/img/t-gallery2.jpg",
//   },
//   {
//     original: "../assets/img/big-gallery.jpg",
//     thumbnail: "../assets/img/t-gallery3.jpg",
//   },
//   {
//     original: "../assets/img/big-gallery.jpg",
//     thumbnail: "../assets/img/t-gallery4.jpg",
//   },
// ];

const Productdesc = () => {
  const useparamData = useParams();
  const [id, setId] = useState(useparamData.id);
  let location = useLocation();

  const [images, setImages] = useState([]);
  const [productRow, setProductRow] = useState({});
  const [reloatedProducts, setReloatedProducts] = useState([]);

  const [selectedCombination, setSelectedCombination] = useState({});
  const loadProduct = async () => {
    let data = await getProduct(id);
    if (data) {
      data.delivered_date = moment()
        .add(parseInt(data.delivered_day), "days")
        .format("LL");
      setProductRow(data);
      if (!data.images.length) {
        data.images.push(data.image);
      }
      let new_images = [];
      for (let i in data.images) {
        new_images.push({
          original: data.images[i],
          thumbnail: data.images[i],
        });
      }
      setImages(new_images);
      loadProducts(data.parent_category_id, data.category_id);

      for (let i in data.remain_stock) {
        if (data.remain_stock[i].quantity) {
          setSelectedCombination(
            JSON.parse(JSON.stringify(data.remain_stock[i].combinations))
          );
          break;
        }
      }
    }
  };

  const loadProducts = async (parent_category_id, category_id) => {
    let data = await getProducts("", "", parent_category_id, category_id, 6);

    for (let i in data.records) {
      if (data.records[i].id === id) {
        data.records.splice(i, 1);
      }
    }
    setReloatedProducts(data.records);
  };

  const [reviewsData, setReviewsData] = useState([]);
  const [totalReview, setTotalReview] = useState(0);
  const [lastReviewId, setLastReviewId] = useState("");

  const [buttonloader, setButtonloader] = useState(false);
  const loadProductReview = async (loading = true) => {
    if (loading) {
      setButtonloader(true);
    }
    let data = await getProductReview({
      product_id: id,
      last_id: lastReviewId,
      limit: 10,
    });
    let review_data = [...reviewsData];
    review_data = review_data.concat(data.records);
    setReviewsData(review_data);
    setTotalReview(data.total);
    if (data.records.length) {
      setLastReviewId(data.records.pop()["id"]);
    }
    if (loading) {
      setButtonloader(false);
    }
  };
  const compareCombinations = (obj1, obj2) => {
    let found = true;

    for (let i in obj1) {
      if (!(obj1[i].key === obj2[i].key && obj1[i].value === obj2[i].value)) {
        found = false;
      }
    }
    return found;
  };

  const checkStock = () => {
    if (!productRow.remain_stock) return false;
    if (!Object.keys(selectedCombination).length) return false;

    let available = false;
    for (let i in productRow.remain_stock) {
      if (
        compareCombinations(
          productRow.remain_stock[i].combinations,
          selectedCombination
        )
      ) {
        available = productRow.remain_stock[i].quantity;
      }
    }
    return available;
  };

  const checkVariation = (type, value) => {
    let match = false;
    for (let i in selectedCombination) {
      if (
        selectedCombination[i].key === type &&
        selectedCombination[i].value === value
      ) {
        match = true;
      }
    }
    return match;
  };
  const selectVariation = (type, value, title_value) => {
    let new_data = [...selectedCombination];
    for (let i in new_data) {
      if (new_data[i].key === type) {
        new_data[i].value = value;
        new_data[i].title = title_value;
      }
    }
    setSelectedCombination(new_data);
  };

  useEffect(() => {
    setId(location?.pathname?.split("/").pop());
  }, [location]);

  useEffect(() => {
    loadProduct();
    loadProductReview(false);
  }, [id]);

  //ToDo: Niharika unused variable
  const myActive = {
    color: "#005062",
    fontWeight: 900,
  };

  const removeWish = async () => {
    setButtonloader(true);
    let res = await removeWishlist(productRow.id);
    let output = resHandler(res);
    if (output.success) {
      loadProduct();
    }
    setButtonloader(false);
  };
  const addWish = async () => {
    setButtonloader(true);
    let res = await addWishlist(productRow.id);
    let output = resHandler(res);
    if (output.success) {
      loadProduct();
    }
    setButtonloader(false);
  };

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      <div className="result-bredcum pt-3">
        <Container className="p-0">
          {/* <a href="/" className="text-decoration-none">
            Back to results
          </a> */}
        </Container>
      </div>
      <section className="product-desc py-2 py-sm-4">
        <Container>
          <Row className="position-relative">
            {productRow.is_bestseller ? (
              <span className="seller-tag">Best Seller</span>
            ) : null}
            <Col lg={4} md={4}>
              <div>
                <ImageGallery
                  items={images}
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              </div>
            </Col>
            <Col lg={8} md={8}>
              <div>
                <div className="orders-list productdesc-order">
                  <ul className="m-0 p-0 list-unstyled">
                    <li className="d-flex align-items-end">
                      <figcaption className="w-100 ps-0">
                        <h6>{productRow.title}</h6>
                        <span className="author-name">
                          by {productRow?.store?.name}
                        </span>
                        <span className="delivery-date">
                          Delivered on: {productRow.delivered_date}
                        </span>
                        {/* <span className="author-name">
                          Item type: Hardcover
                        </span> */}

                        {checkStock() || productRow.bigbuy_product ? (
                          <small className="in-stock my-3 d-block">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/check-stock.svg`}
                              className="me-2"
                              alt="In Stock"
                            />
                            In Stock
                          </small>
                        ) : (
                          <span className="delivery-date my-3">
                            {/* <img
                                src={`${process.env.REACT_APP_BASE_URL}/assets/img/check-stock.svg`}
                                className="me-2"
                              /> */}
                            Out Of Stock
                          </span>
                        )}

                        <div className="product-rating">
                          {[
                            ...Array(
                              parseInt(
                                productRow.rating ? productRow.rating : 0
                              )
                            ),
                          ].map((x, i) => (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/fav-star.svg`}
                              alt="Ratings"
                              key={i}
                            />
                          ))}
                          {!productRow.bigbuy_product && <span className="py-2">
                            {" "}
                            {productRow.reviewer} reviews
                          </span>}
                        </div>

                        <h2 className="mt-2 inclusive-text">
                          {productRow.in_shop_price && (<small>
                            {`In Shop Price: ${appSettings.currency_symbol} ${productRow.in_shop_price}`}
                          </small>)}
                          <span>Inclusive of all taxes:</span>
                          {appSettings.currency_symbol}
                          {productRow.sales_price}
                          {productRow.mrp > productRow.sales_price ? (
                            <small className="d-block">
                              {appSettings.currency_symbol}
                              {productRow.mrp} Save{" "}
                              {appSettings.currency_symbol}
                              {(productRow.mrp - productRow.sales_price).toFixed(2)} (
                              {percentMech(
                                ((productRow.mrp - productRow.sales_price) /
                                  productRow.mrp) *
                                100
                              )}
                              % Off)
                            </small>
                          ) : null}
                          {productRow.price}
                          {productRow.in_shop_price && (
                            <>
                              <br />
                              <small>
                                {appSettings.currency_symbol}
                                {productRow.in_shop_price} Save{" "}
                                {appSettings.currency_symbol}
                                {(productRow.in_shop_price - productRow.price).toFixed(2)}
                                (
                                {percentMech(
                                  ((productRow.in_shop_price - productRow.price) /
                                    productRow.in_shop_price) *
                                  100
                                )}% Off
                                )
                              </small>
                              <br />
                            </>
                          )}
                        </h2>
                      </figcaption>

                      <div className="seller-qnt-des">
                        <div className="align-items-center justify-content-between seller-dflex">
                          {(productRow.variations
                            ? productRow.variations
                            : []
                          ).map((value, index) => {
                            return (
                              <>
                                {false ? ( //value.key === 'color'
                                  <div
                                    className="color-plate text-start mb-2"
                                    key={index}
                                  >
                                    <label className="d-block">
                                      {value.title}:
                                    </label>
                                    {value.value.map((valueA, indexA) => (
                                      <a
                                        href="#"
                                        className="active"
                                        key={index}
                                      ></a>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="color-plate text-start mb-2">
                                    <label className="d-block">
                                      {value.title}:
                                    </label>
                                    {value.value.map((valueA, indexA) => (
                                      <button
                                        key={indexA}
                                        className={
                                          "btn btn-sm btn-outline-dark" +
                                          (checkVariation(value.key, valueA.key)
                                            ? " active"
                                            : "")
                                        }
                                        onClick={() => {
                                          selectVariation(
                                            value.key,
                                            valueA.key,
                                            valueA.title
                                          );
                                        }}
                                        style={{
                                          width: "auto",
                                          borderRadius: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {valueA.title}
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                        <Form className="">
                          {/* <div className="product-selection mb-4">
                            <Form.Select
                              aria-label="Default select example"
                              className="qunty"
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>1</option>
                            </Form.Select>

                            <Form.Select
                              aria-label="Default select example"
                              className="audiobook"
                            >
                              <option value="1">Audiobook</option>
                              <option value="2">Audiobook</option>
                              <option value="3">Audiobook</option>
                            </Form.Select>
                          </div> */}

                          <CartButton
                            id={productRow.id}
                            combinations={selectedCombination}
                            quantity={1}
                            buy={true}
                            type="max"
                          ></CartButton>
                          <CartButton
                            id={productRow.id}
                            combinations={selectedCombination}
                            quantity={1}
                            buy={false}
                            type="max"
                          ></CartButton>

                          {!buttonloader ? (
                            productRow.in_whishlist ? (
                              <button
                                type="button"
                                className="light-btn common-color ms-0  btn btn-primary"
                                onClick={removeWish}
                                style={{
                                  background: "#198754",
                                  padding: "6px 0px",
                                }}
                              >
                                Remove to wishlist
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="light-btn common-color ms-0  btn btn-primary"
                                onClick={addWish}
                                style={{
                                  background: "#198754",
                                  padding: "6px 0px",
                                }}
                              >
                                Add to wishlist
                              </button>
                            )
                          ) : (
                            <Button variant="outline-primary d-flex" size="sm">
                              <Oval color="#4A5073" height={15} width={15} />{" "}
                              &nbsp; Loading
                            </Button>
                          )}
                        </Form>
                      </div>
                    </li>
                    <div
                      className="seller-info mt-3"
                      dangerouslySetInnerHTML={{
                        __html: productRow.description,
                      }}
                    ></div>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <div className="product-full-detail mt-3">
            <h2>Products Details</h2>
            <div dangerouslySetInnerHTML={{ __html: productRow.details }}></div>
          </div>
        </Container>
      </section>

      {totalReview ? (
        <section className="product-desc py-2 py-sm-4">
          <Container>
            <div className="product-full-detail mt-3">
              <h2>Products Review</h2>
              <ul className="p-0 m-0 list-unstyled">
                {reviewsData.map((value, index) => (
                  <li
                    key={`ingh-${index}`}
                    className="m-3"
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,.1)",
                      paddingBottom: "20px",
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <div>
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                          src={value.user_image}
                        />{" "}
                        {value.user_name}
                      </div>
                      <span className="mt-1">
                        <StarRating value={value.rating} />
                      </span>

                      <div className="mt-1">{value.comments}</div>
                      <span
                        className="mt-1"
                        style={{ fontStyle: "italic", opacity: ".7" }}
                      >
                        {" "}
                        {moment(value.created_date).format("LL")}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>

              {totalReview > reviewsData.length ? (
                !buttonloader ? (
                  <Button
                    variant="secondary"
                    onClick={() => loadProductReview()}
                  >
                    Load more
                  </Button>
                ) : (
                  <Button variant="outline-primary d-flex" size="sm">
                    <Oval color="#4A5073" height={15} width={15} /> &nbsp;
                    Loading
                  </Button>
                )
              ) : null}
            </div>
          </Container>
        </section>
      ) : null}

      {/* history-sec */}

      {reloatedProducts.length ? (
        <section className="history-sec mb-5">
          <Container>
            <div className="common-heading mb-4">
              <h2 className="green-text">Product related to this item </h2>
            </div>
            <div className="bg-white">
              <Productlist className="mb-3" products={reloatedProducts} />
            </div>
          </Container>
        </section>
      ) : null}

      {/* history-sec */}
    </div>
  );
};

export default Productdesc;
