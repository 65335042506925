import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

import InfiniteScroll from "react-infinite-scroll-component";

import { getAuth, getUser } from "../helper/auth";
import { getNotifications } from "../helper/request";

import { translate } from "../helper/translate";

import moment from "moment";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const NotificationList = () => {
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [page, setPage] = useState(1);
  //ToDo: Niharika unused variable
  const [limit, setLimit] = useState(10);
  const loadData = async (clear = true) => {
    let data = await getNotifications({ page: page, limit: limit });
    let new_data = [];
    if (clear) {
      new_data = data.records;
    } else {
      new_data = [...notifications].concat(data.records);
    }
    setNotifications(new_data);
    setTotalNotifications(data.total);
  };
  const hasMore = () => {
    return totalNotifications > notifications.length;
  };
  //ToDo: Niharika check what is wrong
  useEffect(() => {
    loadData(false);
  }, [page]);

  const getType = (type) => {
    let title = "System Notification";
    if (type === 1) {
      title = "Admin alert notification";
    }
    return title;
  };

  return (
    <div>
      {/* order-bredcum */}

      <section className="py-3 order-breadcum">
        <Container>
          <Row>
            <Col lg={5}>
              <div className="order-history-bredcum d-flex align-items-center">
                <div className="common-heading me-sm-3 me-2">
                  <h2 className="m-0">{translate("notifications")}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* order-bredcum */}

      {/* shopping-cart */}

      <section className="shoping-cart-sec order-info-sec py-sm-4 py-2">
        <Container>
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => {
              setPage(page + 1);
            }}
            hasMore={hasMore()}
            loader={<h4>{translate("loading")}...</h4>}
          >
            {!notifications.length ? (
              <h5 style={{ textAlign: "center", padding: "200px 0" }}>
                {translate("no_rec")}
              </h5>
            ) : (
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>{translate("sn")}</th>
                      <th>{translate("type")}</th>
                      <th>{translate("title")}</th>
                      <th>{translate("description")}</th>
                      <th>{translate("datetime")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((value, index) => {
                      value.notification_date = moment(
                        value.created_date * 1000
                      ).format("LL");
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{getType(value.type)}</td>
                          <td>{value.title}</td>
                          <td>{value.description}</td>
                          <td>{value.notification_date}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </InfiniteScroll>
        </Container>
      </section>

      {/* shopping-cart */}

      {/* history-sec */}

      {/* <section className="history-sec mb-5">
        <Container>
          <div className="common-heading mb-4">
            <h2 className="green-text">Product related to this item </h2>
          </div>
          <div className="bg-white">
          <Productlist className="mb-3" />
          </div>
        </Container>
      </section> */}

      {/* history-sec */}
    </div>
  );
};

export default NotificationList;
