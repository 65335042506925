import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartButton from "../components/CartButton";
import { percentMech } from "../helper/response";
import { translate } from "../helper/translate";

const Productlist = (props) => {
  return (
    <div>
      <ul className="list-unstyled d-flex flex-wrap">
        {props?.products?.map((value, index) => {
          let short_description = value.description
            ? value.description.replace(/<[^>]+>/g, "")
            : "";
          if (short_description.length > 150)
            short_description = short_description.substring(0, 150) + "...";
          if (value.product_type === undefined)
            return (
              <li key={index}>
                <div className="product-lister" style={{ position: "relative" }}>
                  {value.is_bestseller ? (
                    <span className="seller-tag">{translate("best_seller")}</span>
                  ) : null}
                  <Link to={`/product/${value.id}`}>
                    <figure
                      className="m-0"
                      style={{
                        minWidth: "196px",
                        minHeight: "196px",
                        display: "flex",
                      }}
                    >
                      <img
                        src={value.image}
                        alt=""
                        style={{
                          maxWidth: "196px",
                          maxHeight: "196px",
                          margin: "auto",
                        }}
                      />
                    </figure>
                    <span className="d-block">{value.title}</span>
                  </Link>

                  {value.bigbuy_product === undefined ? <div className="price-detail">
                    <div className="d-flex align-items-center justify-content-between dis-offer-card">
                      <span className="product-price">€{value.sales_price}</span>
                      {value.mrp > value.sales_price ? (
                        <p>
                          {percentMech(
                            ((value.mrp - value.sales_price) / value.mrp) * 100
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>
                    <p>{short_description}</p>
                  </div> : <div className="price-detail">
                    <div className="d-flex align-items-center justify-content-between dis-offer-card">
                      <span className="product-price">€{value.price}</span>
                      {value.in_shop_price > value.price ? (
                        <p>
                          {percentMech(
                            ((value.in_shop_price - value.price) / value.in_shop_price) * 100
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>
                    {/* <span dangerouslySetInnerHTML={{ __html: value.description }} /> */}
                  </div>}

                  <div className="rating-main d-flex justify-content-between align-items-center">
                    {value.bigbuy_product === undefined && (<div className="rating">
                      {[...Array(parseInt(value?.rating))].map((x, i) => (
                        <i className="fas fa-star" key={i}></i>
                      ))}
                      {/* <i className="fas fa-star-half-alt"></i> */}
                      {value.rating ? (
                        <span className="rating-rate1 ms-2">{value.rating}</span>
                      ) : null}
                    </div>)}

                    <CartButton
                      id={value.id}
                      combinations={value.bigbuy_product === undefined ? value?.remain_stock[0]?.combinations : [
                        {
                          "key": "1",
                          "title": "1",
                          "value": "1",
                          "found": true
                        }
                      ]}
                      quantity={1}
                      buy={false}
                      type="small"
                      changeCart={props.changeCart ? props.changeCart : () => { }}
                    ></CartButton>
                  </div>
                </div>
              </li>
            );
          else if (value.sales_price > 0)
            return (
              <li key={index}>
                <div className="product-lister" style={{ position: "relative" }}>
                  {value.is_bestseller ? (
                    <span className="seller-tag">{translate("best_seller")}</span>
                  ) : null}
                  <Link to={`/product/${value.id}`}>
                    <figure
                      className="m-0"
                      style={{
                        minWidth: "196px",
                        minHeight: "196px",
                        display: "flex",
                      }}
                    >
                      <img
                        src={value.image[0].split(",")[0]}
                        alt=""
                        style={{
                          maxWidth: "196px",
                          maxHeight: "196px",
                          margin: "auto",
                        }}
                      />
                    </figure>
                    <span className="d-block">{value.title}</span>
                  </Link>

                  <div className="price-detail">
                    <div className="d-flex align-items-center justify-content-between dis-offer-card">
                      <span className="product-price">€{value.sales_price}</span>
                      {value.mrp > value.sales_price ? (
                        <p>
                          {percentMech(
                            ((value.mrp - value.sales_price) / value.mrp) * 100
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>
                    <p>{short_description}</p>
                  </div>

                  <div className="rating-main d-flex justify-content-between align-items-center">
                    <CartButton
                      id={value.id}
                      combinations={value.product_type === 1 ? value?.remain_stock[0]?.combinations : [
                        {
                          "key": "1",
                          "title": "1",
                          "value": "1",
                          "found": true
                        }
                      ]}
                      quantity={1}
                      buy={false}
                      type="small"
                      changeCart={props.changeCart ? props.changeCart : () => { }}
                    ></CartButton>
                  </div>
                </div>
              </li>)
          else return <React.Fragment key={index}></React.Fragment>
        })}
      </ul>
    </div >
  );
};

export default Productlist;
