import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Productlist from "../components/Productlist";
import { UserContext } from "../helper/contextApi";

import { Link, useHistory } from "react-router-dom";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import {
  getCart,
  getProducts,
  getWishlist,
  removeCart,
  updateCart,
} from "../helper/request";
import { resHandler } from "../helper/response";

import { translate } from "../helper/translate";

import moment from "moment";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const Cart = () => {
  const history = useHistory();
  const globalData = useContext(UserContext);

  const [cartData, setCartData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [notIds, setNotIds] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [wishlists, setWishlist] = useState([]);
  const loadCart = async () => {
    let items = 0;
    let amount = 0;
    let selected_cat = [];
    let selected_ids = [];
    let res = await getCart();
    if (res.data.data) {
      if (res.data.data.items) {
        for (let i in res.data.data.items) {
          items += res.data.data.items[i].quantity;
          amount +=
            res.data.data.items[i].sales_price *
            res.data.data.items[i].quantity;

          selected_cat.push(res.data.data.items[i].category_id);
          selected_ids.push(res.data.data.items[i].product_id);
        }
        setSelectedCategory(selected_cat);
        setNotIds(selected_ids);

        setCartData(res.data.data.items);
        setTotalItems(items);
        setTotalAmount(amount);
        setTimeout(() => {
          loadRelatedProducts();
        });
      }
    } else {
      setCartData([])
    }
  };
  const loadRelatedProducts = async () => {
    let data = await getProducts(
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      0,
      0,
      [],
      "",
      0,
      "",
      selectedCategory,
      [],
      notIds
    );
    setRelatedProducts(data.records);
  };
  const loadWishlist = async () => {
    let data = await getWishlist();
    setWishlist(data);
  };

  const updateQuantity = async (product_id, combinations, quantity) => {
    let res = await updateCart(product_id, combinations, quantity);
    let output = resHandler(res);
    if (output.success) {
      loadCart();
    }
  };
  const removeItem = async (id) => {
    let res = await removeCart(id);
    let output = resHandler(res);
    if (output.success) {
      globalData.load_cart();
      loadCart();
    }
  };
  const gotoProceed = () => {
    history.push("/checkout");
  };

  useEffect(() => {
    loadCart();
    loadWishlist();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      {/* shopping-cart */}

      <section className="shoping-cart-sec py-md-5 py-3 ">
        <Container>
          <div className="common-heading mb-4">
            <h2>{translate("your_shop_cart")}</h2>
          </div>
          {cartData.length ? (
            <Row>
              <Col lg={8} className="mb-3">
                <div>
                  <Form>
                    <ul className="p-0 m-0 list-unstyled">
                      {cartData.map((value, index) => {
                        value.delivered_date = moment()
                          .add(parseInt(value.delivered_day), "days")
                          .format("LL");
                        return (
                          <>
                            <li className="position-relative mb-2" key={index}>
                              <Row className="align-items-center">
                                <Col lg={8} md={8}>
                                  {/* <Form.Check type="checkbox" /> */}
                                  <div className="orders-list">
                                    <ul className="m-0 p-0 list-unstyled">
                                      <li className="d-flex">
                                        <figure
                                          className="m-0"
                                          style={{ display: "flex" }}
                                        >
                                          <img
                                            src={value.image}
                                            alt=""
                                            style={{ margin: "auto" }}
                                          />
                                        </figure>
                                        <figcaption>
                                          <h6>
                                            <a href="#">{value.title}</a>
                                          </h6>

                                          <div className="d-flex cart-qunt-flex">
                                            <div>
                                              <span className="author-name">
                                                {translate("by")}{" "}
                                                {value.store.name}
                                              </span>
                                              <span className="delivery-date">
                                                {translate("delivered_on")} :{" "}
                                                {value.delivered_date}
                                              </span>
                                            </div>

                                            <div className="seller-qnt-des text-center">
                                              <Form className="shadow-none px-2 py-0">
                                                <div className="product-selection mb-4 justify-content-center">
                                                  <Form.Select
                                                    onChange={(e) => {
                                                      updateQuantity(
                                                        value.product_id,
                                                        value.combinations,
                                                        e.target.value
                                                      );
                                                    }}
                                                    value={value.quantity}
                                                    aria-label="Default select example"
                                                    className="qunty ms-0"
                                                  >
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                  </Form.Select>
                                                </div>
                                              </Form>
                                            </div>
                                          </div>
                                          {value.combinations.map(
                                            (value, index) => (
                                              <span key={index}>
                                                {" "}
                                                {capitalizeFirstLetter(
                                                  value.key
                                                )}{" "}
                                                : {value.title}
                                              </span>
                                            )
                                          )}
                                          <h2>
                                            <span>
                                              {translate("order_amount")}:
                                            </span>
                                            {appSettings.currency_symbol}
                                            {value.sales_price * value.quantity}
                                          </h2>
                                        </figcaption>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>

                                <Col lg={4} md={4}>
                                  <ul className="p-0 m-0 list-unstyled delete-order">
                                    <li>
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          removeItem(value.id);
                                        }}
                                        className="text-danger"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/assets/img/delete.svg`}
                                          alt=""
                                          className="me-1"
                                        />
                                        {translate("delete")}
                                      </a>
                                    </li>
                                    {/* <li>
                                          <a href="#">
                                            {" "}
                                            <img
                                              src="./assets/img/save-icon.svg"
                                              alt=""
                                              className="me-1"
                                            />
                                            Save for Later
                                          </a>
                                        </li> */}
                                    <li>
                                      <Link
                                        to={`./search/${value.parent_category_id}/${value.category_id}`}
                                        className="text-primary"
                                      >
                                        {" "}
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/assets/img/similiar-product.svg`}
                                          alt=""
                                          className="me-1"
                                        />
                                        {translate("similar_prod")}
                                      </Link>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </li>
                            <hr />
                          </>
                        );
                      })}

                      <hr />
                    </ul>

                    <div className="d-flex flex-wrap align-items-center subtotal justify-content-end mb-2">
                      <h2 className="m-0">
                        {translate("subtotal")} ({totalItems}{" "}
                        {translate("items")}):{" "}
                        <b>
                          {appSettings.currency_symbol}
                          {totalAmount}
                        </b>{" "}
                      </h2>
                      <Button
                        onClick={gotoProceed}
                        variant="primary"
                        type="submit"
                        className="light-btn"
                      >
                        {translate("process_to_buy")}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>

              <Col lg={4}>
                <div className="product-related-sec">
                  <h2>{translate("prod_rel_items")}</h2>

                  <ul className="m-0 p-0 list-unstyled">
                    {relatedProducts.map((value, key) => (
                      <li key={key}>
                        <figure className="m-0" style={{ display: "flex" }}>
                          <img
                            src={value.image}
                            alt=""
                            style={{ margin: "auto" }}
                          />
                        </figure>
                        <figcaption>
                          <h5>{value.title}</h5>
                          <h3 className="d-flex justify-content-between">
                            {appSettings.currency_symbol}
                            {value.sales_price}{" "}
                            <Link to={`./product/${value.id}`}>
                              {translate("view")}
                            </Link>
                          </h3>
                        </figcaption>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                className="flex-wrap card"
                style={{
                  padding: "40px",
                }}
              >
                {translate("no_rec_cart")}
              </Col>
            </Row>
          )}
        </Container>
      </section>

      {/* shopping-cart */}

      {/* history-sec */}

      <section className="history-sec mb-5">
        <Container>
          <div className="common-heading mb-4">
            <h2>
              {translate("your_sav_items")}{" "}
              <span>
                ({wishlists.length} {translate("items")})
              </span>{" "}
            </h2>
          </div>

          <div>
            <Productlist
              className="mb-3"
              products={wishlists}
              changeCart={() => {
                loadCart();
                loadWishlist();
              }}
            />
          </div>
        </Container>
      </section>

      {/* history-sec */}
    </div>
  );
};

export default Cart;
