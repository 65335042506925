import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { addToCart } from "../helper/request";
import { resHandler } from "../helper/response";
import { translate } from "../helper/translate";

import { UserContext } from "../helper/contextApi";
const CartButton = (props) => {
  //ToDo: Niharika unused variable
  const globalData = useContext(UserContext);
  const history = useHistory();
  const doAction = async () => {
    let res = await addToCart(props.id, props.combinations);
    let output = resHandler(res);
    if (output.success) {
      globalData.load_cart();
      if (props.buy) {
        history.push("/cart");
      } else {
        //if(window.location.href.indexOf('/cart')>-1){
        if (props.changeCart) {
          props.changeCart();
        }
        //}
      }
    }
  };

  return (
    <>
      {props.type === "max" && !props.buy ? (
        <Button
          onClick={doAction}
          variant="primary"
          type="button"
          className={`light-btn common-color ms-0 ${props.className ? props.className : ""
            }`}
        >
          {translate("add_to_cart")}
        </Button>
      ) : null}
      {props.type === "max" && props.buy ? (
        <Button
          onClick={doAction}
          variant="primary"
          type="button"
          className={`light-btn ms-0 ${props.className ? props.className : ""}`}
        >
          {translate("buy_now")}
        </Button>
      ) : null}
      {props.type === "small" ? (
        <a
          onClick={doAction}
          style={{ cursor: "pointer" }}
          className="buy-now text-decoration-none mb-0"
        >
          {translate("add_to_cart")}
        </a>
      ) : null}
    </>
  );
};

export default CartButton;
