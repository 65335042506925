import { Container } from "react-bootstrap";
import { getLanguage } from "../../../helper/auth";

const FAQ = () => {
  
  const language = getLanguage();
  return (
    language === "de" 
    ?
    <Container className="pt-4">
      <h2 className="account-heading">Häufig gestellte Fragen (FAQs)</h2>
      <ol>
      <li>
      Was ist Amzio?<br />
      Amzio ist ein Online-Shop, der eine breite Palette von Produkten anbietet, darunter Elektronik, Mode, Haushaltswaren und mehr.
      </li>
    <li>
    Wie kann ich eine Bestellung aufgeben?<br />
    Durchsuchen Sie einfach unsere Website, wählen Sie die gewünschten Artikel aus und gehen Sie zur Kasse. Folgen Sie den Aufforderungen, um die erforderlichen Informationen anzugeben und Ihren Einkauf abzuschließen.
    </li>
    <li>
    Ist es sicher, bei Amzio einzukaufen?<br />
    Ja, Amzio legt großen Wert auf die Sicherheit Ihrer Daten. Wir verwenden verschlüsselte Verbindungen für alle Transaktionen, um ein sicheres Einkaufserlebnis zu gewährleisten.
    </li>
    <li>
    Welche Zahlungsarten werden akzeptiert?<br />
    Wir akzeptieren verschiedene Zahlungsarten, einschließlich Kredit-/Debitkarten, PayPal und andere sichere Zahlungsoptionen. Sie können die vollständige Liste während des Bestellvorgangs finden.
    </li>
    <li>
    Wie kann ich meine Bestellung verfolgen?<br />
    Sobald Ihre Bestellung versandt wurde, erhalten Sie eine Bestätigungs-E-Mail mit einer Tracking-Nummer. Sie können diese Nummer verwenden, um Ihr Paket auf unserer Website oder auf der Website des jeweiligen Kurierdienstes zu verfolgen.
    </li>
    <li>
    Was ist das Rückgaberecht bei Amzio?<br />
    Wir bieten ein unkompliziertes Rückgaberecht. Wenn Sie mit Ihrem Kauf nicht zufrieden sind, können Sie innerhalb von 15 Tagen nach Erhalt Ihrer Bestellung eine Rückgabe veranlassen. Detaillierte Anweisungen finden Sie auf unserer Rückgabe-Seite.
    </li>
    <li>
    Bieten Sie internationalen Versand an?<br />
    Derzeit bieten wir keinen internationalen Versand an. Wir entschuldigen uns für etwaige Unannehmlichkeiten. Unser Service ist im Moment auf Inlandslieferungen beschränkt. Wenn es in Zukunft Änderungen in unserer Versandpolitik gibt, werden wir unsere Kunden entsprechend informieren.
    </li>
    <li>
    Wie kann ich den Amzio-Kundenservice kontaktieren?<br />
    Sie können unseren Kundendienst über <a href="mailto:support@amzio.de">support@amzio.de</a>, or you can use the contact form on our website. We strive to respond to inquiries promptly.
    </li>
    <li>
    Gibt es Rabatte oder Sonderangebote?<br />
    Amzio bietet regelmäßig Rabatte und Sonderaktionen an. Behalten Sie unsere Website im Auge oder abonnieren Sie unseren Newsletter, um über die neuesten Angebote informiert zu werden.
    </li>
    <li>
    Kann ich meine Bestellung ändern oder stornieren, nachdem ich sie aufgegeben habe?<br />
    Wir bearbeiten Bestellungen schnell, um eine schnelle Lieferung zu gewährleisten. Wenn Sie Ihre Bestellung ändern oder stornieren möchten, wenden Sie sich bitte so schnell wie möglich an unseren Kundendienst, und wir werden unser Bestes tun, um Ihnen zu helfen.
    </li>
    <li>
    Gibt es ein Treueprogramm für Stammkunden?<br />
    Ja, wir schätzen unsere treuen Kunden. Amzio hat ein Treueprogramm, das unseren geschätzten Kunden exklusive Vorteile, Rabatte und einen frühzeitigen Zugang zu Sonderangeboten bietet.
    </li>
    </ol>
    Wenn Sie weitere Fragen oder Anliegen haben, können Sie sich gerne an uns wenden!
          
    </Container>
    :
    <Container className="pt-4">
    <h2 className="account-heading">Frequently Asked Questions (FAQs)</h2>
    <ol>
    <li>
    What is Amzio?<br />
    Amzio is an online store that offers a wide range of products, including electronics, fashion, home goods, and more.
    </li>
    <li>
      How can I place an order?<br />
    Simply browse our website, select the items you want, and proceed to checkout. Follow the prompts to provide necessary information and complete your purchase.
    </li>
    <li>
      Is it safe to shop on Amzio?<br />
    Yes, Amzio prioritizes the security of your information. We use encrypted connections for all transactions, ensuring a secure shopping experience.
    </li>
    <li>
    What payment methods are accepted?<br />
    We accept various payment methods, including credit/debit cards, PayPal, and other secure payment options. You can find the full list during the checkout process.
    </li>
    <li>
    How can I track my order?<br />
    Once your order is shipped, you'll receive a confirmation email with a tracking number. You can use this number to track your package on our website or the respective courier's site.
    </li>
    <li>
    What is Amzio's return policy?<br />
    We offer a hassle-free return policy. If you're not satisfied with your purchase, you can initiate a return within 15 days of receiving your order. Please refer to our Returns page for detailed instructions.
    </li>
    <li>
    Do you offer international shipping?<br />
    Currently, we do not offer international shipping. We apologize for any inconvenience. Our services are limited to domestic deliveries at the moment. If there are changes to our shipping policy in the future, we will update our customers accordingly..
    </li>
    <li>
    How can I contact Amzio's customer support?<br />
    You can reach our customer support team by <a href="mailto:support@amzio.de">support@amzio.de</a>, or you can use the contact form on our website. We strive to respond to inquiries promptly.
    </li>
    <li>
    Are there any discounts or promotions?<br />
    Amzio regularly offers discounts and promotions. Keep an eye on our website or subscribe to our newsletter to stay updated on the latest deals and offers.
    </li>
    <li>
    Can I change or cancel my order after placing it?<br />
    We process orders quickly to ensure prompt delivery. If you need to make changes or cancel your order, please contact our customer support as soon as possible, and we'll do our best to assist you.
    </li>
    <li>
    Is there a loyalty program for regular customers?<br />
    Yes, we appreciate our loyal customers. Amzio has a loyalty program that offers exclusive benefits, discounts, and early access to promotions for our valued customers.
    </li>
    </ol>
    Feel free to contact us if you have any additional questions or concerns!
          
    </Container>
  );
};

export default FAQ;
