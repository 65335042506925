import { Container } from "react-bootstrap";

const Imprint = () => {
  return (
    <Container className="pt-4">
      <h2 className="account-heading">Impressum</h2>
      <p> <b>Gesetzliche Anbieterkennung:</b></p>
      <address>
      Yahya Khan Noor
        <br />
        Amzio www.amzio.de
        <br />
        Carl-Goerdeler-Straße 3-5
        <br />
        60320 Frankfurt am Main
        <br />
        Deutschland
        <br />
        <abbr title="Phone">Telefon:</abbr> +491724182490
        <br />
        <abbr title="Email">E-Mail: </abbr> 
        <a href="mailto: info@amzio.de">info@amzio.de</a>
      </address>
      <p><b>USt-IdNr.: DE352061687</b></p>
      <p>
        <h6>Alternative Streitbeilegung:</h6>
        Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar
unter &nbsp;
        <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr.</a>
      </p>
      <p>
      Wir sind nicht bereit und nicht verpflichtet, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.
      </p>
    </Container>
  );
};

export default Imprint;
