import { Container } from "react-bootstrap";
import { getLanguage } from "../../../helper/auth";

const AboutUs = () => {

  const language = getLanguage();
  return (
    language === "de" 
    ? 
    <Container className="pt-4">
      <h2 className="account-heading">Über Uns</h2>
      <p> 
        Willkommen bei Amzio, wo Ihr Online-Einkaufserlebnis auf eine unvergleichliche Vielfalt und Qualität trifft. Gegründet mit der Leidenschaft, Kunden mit außergewöhnlichen Produkten zu verbinden, ist Amzio Ihre Anlaufstelle für alles, was innovativ, stilvoll und zuverlässig ist. Unsere sorgfältig zusammengestellte Auswahl erstreckt sich über alle Kategorien und stellt sicher, dass Sie genau das finden, wonach Sie suchen, egal ob es sich um modernste Elektronik, trendige Mode oder unverzichtbare Haushaltswaren handelt.
      </p>
      <p>
        Bei Amzio steht die Kundenzufriedenheit an erster Stelle. Unser Engagement für herausragende Leistungen spiegelt sich in der nahtlosen Einkaufsmöglichkeit wider, die wir Ihnen bieten. Mit einer benutzerfreundlichen Oberfläche und sicheren Transaktionen bemühen wir uns, Ihr Online-Einkaufserlebnis so angenehm und stressfrei wie möglich zu gestalten. Wir wissen, dass Vertrauen im E-Commerce von größter Bedeutung ist, und wir gehen einen Schritt weiter, um die Echtheit und Qualität jedes von uns angebotenen Produkts zu garantieren.
      </p>
      <p>
        Was Amzio auszeichnet, sind nicht nur die Produkte, die wir verkaufen, sondern auch die Gemeinschaft, die wir aufgebaut haben. Wir glauben an eine enge Beziehung zu unseren Kunden, hören auf Ihr Feedback und entwickeln uns ständig weiter, um Ihre sich verändernden Bedürfnisse zu erfüllen. Unser Team setzt sich dafür ein, dass Amzio Ihre vertrauenswürdige Anlaufstelle bleibt, um die neuesten Trends und zeitlosen Klassiker zu entdecken.
      </p>
      <p>
        Danke, dass Sie sich für Amzio entschieden haben - wo außergewöhnliche Produkte und außergewöhnlicher Service zusammenkommen. Wir freuen uns darauf, ein Teil Ihrer Online-Shopping-Reise zu sein und jeden Einkauf bei uns zu einem angenehmen Erlebnis zu machen.
      </p>
    </Container>
    :
    <Container className="pt-4">
      <h2 className="account-heading">About Us</h2>
      <p> 
        Welcome to Amzio, where your online shopping experience meets unparalleled variety and quality. Founded with a passion for connecting customers with exceptional products, Amzio is your go-to destination for all things innovative, stylish, and reliable. Our carefully curated selection spans across categories, ensuring you find precisely what you're looking for, whether it's cutting-edge electronics, trendy fashion, or essential home goods.
      </p>
      <p>
        At Amzio, we prioritize customer satisfaction above all else. Our commitment to excellence is reflected in the seamless shopping journey we provide. With a user-friendly interface and secure transactions, we strive to make your online shopping experience as enjoyable and stress-free as possible. We understand that trust is paramount in e-commerce, and we go the extra mile to guarantee the authenticity and quality of every product we offer.
      </p>
      <p>
        What sets Amzio apart is not just the products we sell, but the community we've built. We believe in fostering a sense of connection with our customers, listening to your feedback, and constantly evolving to meet your evolving needs. Our team is dedicated to ensuring that Amzio remains your trusted destination for discovering the latest trends and timeless classics.
      </p>
      <p>
        Thank you for choosing Amzio – where exceptional products and exceptional service come together. We look forward to being a part of your online shopping journey and making every purchase with us a delightful experience.
      </p>
    </Container>
  );
};

export default AboutUs;
