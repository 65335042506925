import { Container } from "react-bootstrap";

const RefundPolicy = () => {
  return (
    <Container className="pt-4">
      <h2 className="account-heading">Widerrufsrecht für Verbraucher</h2>
      <p>
      (Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden können.)
      </p>

      <h6>Widerrufsbelehrung</h6>
      <h6>Widerrufsrecht</h6>
      <p>
      Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br />
      Die Widerrufsfrist beträgt 14 Tage ab dem Tag,
        <ul>
          <li>
          an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat, sofern Sie
eine oder mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und diese einheitlich geliefert wird bzw. werden;
          </li>
          <li>
          an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat, sofern
Sie mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und diese getrennt geliefert werden;
          </li>
          <li>
          an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz
genommen haben bzw. hat, sofern Sie eine Ware bestellt haben, die in mehreren Teilsendungen oder Stücken geliefert wird;
          </li>
        </ul>
      </p>

      <p>
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns&nbsp;
        <b>
        (Yahya Khan Noor, Carl-Goerdeler-Straße 3-5, 60320 Frankfurt am Main,
Telefonnr.: 01724182490, E-Mail-Adresse: support@amzio.de)
        </b>
        &nbsp;mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-
Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>
      <p>
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
absenden.
      </p>

      <h6>Folgen des Widerrufs</h6>
      <p>
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die
Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem
Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
      </p>

      <p>
      Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass
Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
      </p>

      <p>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
Vertrags unterrichten, an uns oder an Amzio, Carl-Goerdeler-Straße 3-5, 60320 Frankfurt am Main zurückzusenden oder zu übergeben.
Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14 Tagen absenden.
      </p>

      <p>Wir tragen die Kosten der Rücksendung der Waren.</p>

      <p>
      Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
      </p>

      <h6>Ausschluss- bzw. Erlöschensgründe</h6>
      <p>
      Das Widerrufsrecht besteht nicht bei Verträgen
        <ul>
          <li>
          ur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den
Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;
          </li>
          <li>
          zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;
          </li>
          <li>
          zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach
Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer
keinen Einfluss hat;
          </li>
          <li>
          zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit Ausnahme von Abonnement-Verträgen.
          </li>
        </ul>
      </p>

      <p>
      Das Widerrufsrecht erlischt vorzeitig bei Verträgen
        <ul>
          <li>
          zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn
ihre Versiegelung nach der Lieferung entfernt wurde;
          </li>
          <li>
          zur Lieferung von Waren, wenn diese nach der Lieferung aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;
          </li>
          <li>
          zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der
Lieferung entfernt wurde.
          </li>
        </ul>
      </p>

      <hr />

      <h6>Muster-Widerrufsformular</h6>
      <p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
      </p>

      <p>
        <ul>
          <li>
            An Yahya Khan Noor, Waldecker Straße 19, 60433 Frankfurt am Main,
            E-Mail-Adresse: support@amzio.de :An Yahya Khan Noor, Carl-Goerdeler-Straße 3-5, 60320 Frankfurt am Main, E-Mail-Adresse: support@amzio.de :
          </li>
          <li>
          Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/
die Erbringung der folgenden Dienstleistung (*)
          </li>
          <li>Bestellt am (*)/ erhalten am (*)</li>
          <li>Name des/ der Verbraucher(s)</li>
          <li>Anschrift des/ der Verbraucher(s)</li>
          <li>
          Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)
          </li>
          <li>Datum</li>
        </ul>
      </p>
      <p>(*) Unzutreffendes streichen.</p>
    </Container>
  );
};

export default RefundPolicy;
