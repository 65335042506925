import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { Oval } from "react-loader-spinner";

import { getAuth, getUser } from "../helper/auth";
import {
  addAddress,
  getAddresses,
  removeAddress,
  updateAddress,
} from "../helper/request";
import { resHandler } from "../helper/response";

import { translate } from "../helper/translate";

let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const Addresses = () => {
  const [addresses, setAddresses] = useState([]);
  const loadAddresses = async () => {
    let res = await getAddresses();
    if (res) {
      setAddresses(res);
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleConfirmClose = () => {
    setShowConfirmModal(false);
  };
  const deafultAddress = {
    id: 0,
    name: "",
    type: 0,
    mobile: "",
    email: "",
    address_title: "",
    address: "",
    pincode: "",
    city: "",
    country: "",
    house_no: ""
  };
  const [addressRow, setAddressRow] = useState(deafultAddress);
  const addNewAddress = () => {
    setValidationData([]);
    setAddressRow(deafultAddress);
    setShowModal(true);
  };
  const handleChange = (e) => {
    let data = { ...addressRow };
    data[e.target.name] = e.target.value;
    setAddressRow(data);
  };
  const [validationData, setValidationData] = useState([]);
  const checkValidationError = (key, type) => {
    let res = false;
    for (let i in validationData) {
      if (key === validationData[i].param) {
        if (type === "msg") {
          res = validationData[i].msg;
        } else {
          res = true;
        }
      }
    }
    return res;
  };
  const [buttonloader, setButtonloader] = useState(false);
  const addAdressAction = async () => {
    setButtonloader(true);
    let data = { ...addressRow };
    let res = await addAddress(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadAddresses();
    setButtonloader(false);
  };

  const openEditModal = async (data) => {
    setValidationData([]);
    setAddressRow({ ...data });
    setShowModal(true);
  };
  const confirmDelete = async (data) => {
    setAddressRow({ ...data });
    setShowConfirmModal(true);
  };

  const updateAddressAction = async () => {
    setButtonloader(true);
    let data = { ...addressRow };
    let res = await updateAddress(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadAddresses();
    setButtonloader(false);
  };
  const deleteAddress = async () => {
    setButtonloader(true);
    let data = { ...addressRow };
    let res = await removeAddress(data.id);
    let output = resHandler(res);
    if (output.success) {
      handleConfirmClose();
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    loadAddresses();
    setButtonloader(false);
  };
  return (
    <div>
      {/* order-bredcum */}

      <section className="py-3 order-breadcum" style={{ minHeight: "400px" }}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="row">
                <div className="delivery-sec py-3">
                  <h2 className="checkout-heading mb-3">
                    {translate("addressess")}
                    <button
                      className="btn btn-primary"
                      onClick={addNewAddress}
                      style={{
                        float: "right",
                      }}
                    >
                      {translate("add_new")}
                    </button>
                  </h2>
                </div>
              </div>
            </Col>

            {addresses.length ? (
              <Col lg={12}>
                <Row>
                  {addresses.map((value, index) => (
                    <Col lg={6} md={6} className="p-0" key={index}>
                      <div className="delivery-ad-radio">
                        <span className="custom_radio active">
                          {value.address_title}
                          <p>
                            {value.address} , {value.pincode} , {value.city} ,{" "}
                            {value.country}
                          </p>

                          <span className="checked_outer"></span>
                        </span>
                        <span>
                          <a
                            href="#"
                            style={{ float: "right" }}
                            onClick={() => confirmDelete(value)}
                            className="pt-1 pe-3"
                          >
                            {translate("delete")}
                          </a>
                          <a
                            href="#"
                            style={{ float: "right" }}
                            onClick={() => openEditModal(value)}
                            className="pt-1 pe-3"
                          >
                            {translate("edit")}
                          </a>
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            ) : (
              <Row>
                <Col
                  className="flex-wrap card"
                  style={{
                    padding: "40px",
                  }}
                >
                  {translate("no_rec_add")}
                </Col>
              </Row>
            )}
          </Row>
        </Container>
      </section>

      {/* add-address */}

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        className="comon-modal"
      >
        <Modal.Header closeButton>
          {addressRow.id === 0 ? (
            <Modal.Title className="h6">{translate("address_add")}</Modal.Title>
          ) : (
            <Modal.Title className="h6">
              {translate("address_edit")}
            </Modal.Title>
          )}
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address_title")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("address_title")}
                    onChange={(event) => handleChange(event)}
                    name="address_title"
                    type="text"
                    value={addressRow.address_title}
                    placeholder={translate("enter_address_title")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address_title", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address_type")}</Form.Label>

                  <Form.Select
                    isInvalid={checkValidationError("type")}
                    onChange={(event) => handleChange(event)}
                    name="type"
                    value={addressRow.type}
                  >
                    <option value="0" defaultValue>
                      {translate("personal")}
                    </option>
                    <option value="1" defaultValue>
                      {translate("commercial")}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("type", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label> {translate("Name")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("name")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.name}
                    type="text"
                    name="name"
                    placeholder={translate("enter_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("name", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("mobile_number")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("mobile")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.mobile}
                    name="mobile"
                    placeholder={translate("enter_mobile_number")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("mobile", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("email")}</Form.Label>
                  <Form.Control
                    type="email"
                    isInvalid={checkValidationError("email")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.email}
                    name="email"
                    placeholder={translate("enter_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("email", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("country")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("country")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.country}
                    name="country"
                    placeholder={translate("enter_country")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("country", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("city")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("city")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.city}
                    name="city"
                    placeholder={translate("enter_city")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("city", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("pincode")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={checkValidationError("pincode")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.pincode}
                    name="pincode"
                    placeholder={translate("enter_pincode")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("pincode", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("address")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={checkValidationError("address")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.address}
                    name="address"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("house_no")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={checkValidationError("address")}
                    onChange={(event) => handleChange(event)}
                    value={addressRow.house_no}
                    name="house_no"
                    rows={5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("address", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {!buttonloader ? (
              addressRow.id === 0 ? (
                <Button variant="secondary" onClick={() => addAdressAction()}>
                  {translate("add")}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => updateAddressAction()}
                >
                  {translate("update")}
                </Button>
              )
            ) : (
              <Button variant="outline-primary d-flex" size="sm">
                <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                {translate("loading")}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={handleConfirmClose}
        className="comon-modal"
      >
        <Modal.Header closeButton style={{ background: "#fff" }}>
          <Modal.Title className="h6">
            {translate("confirm_add_title")}?
          </Modal.Title>
          :<Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#fff" }}>
          <p>{translate("confirm_add_desc")}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            style={{
              width: "auto",
              background: "#6c757d",
              borderColor: "#6c757d",
            }}
            className="btn btn-secondary"
            onClick={handleConfirmClose}
          >
            {translate("cancel")}
          </button>
          <button
            type="button"
            style={{
              width: "auto",
              background: "#dc3545",
              borderColor: "#dc3545",
            }}
            className="btn btn-danger"
            onClick={deleteAddress}
          >
            {translate("delete")}
          </button>
        </Modal.Footer>
      </Modal>

      {/* add-address */}
    </div>
  );
};

export default Addresses;
