import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartButton from "../components/CartButton";
import { percentMech } from "../helper/response";

import { getAppSettings } from "../helper/auth";
import { translate } from "../helper/translate";

import moment from "moment";
import { Col, Row } from "react-bootstrap";
const ProductListing = (props) => {
  const [products, setProducts] = useState(
    props.products ? props.products : []
  );
  useEffect(() => {
    setProducts(props.products);
  });

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      <Row className="p-0 align-items-center product-row">
        {products.map((value, index) => {
          let short_description = value.description.replace(/<[^>]+>/g, "");
          if (short_description.length > 150)
            short_description = short_description.substring(0, 150) + "...";
          value.delivered_date = moment()
            .add(parseInt(value.delivered_day), "days")
            .format("LL");

          if (value.product_type === undefined)
            return (
              <>
                <Col lg={9} className="position-relative" key={index}>
                  {value.is_bestseller ? (
                    <span className="seller-tag">{translate("best_seller")}</span>
                  ) : null}
                  <div className="orders-list productdesc-order list-view-order mt-4 mb-3">
                    <ul className="m-0 p-0 list-unstyled prdesc-li">
                      <li className="d-flex">
                        <Link to={`/product/${value.id}`}>
                          <figure
                            className="m-0 "
                            style={{
                              minWidth: "196px",
                              minHeight: "196px",
                              display: "flex",
                            }}
                          >
                            <img
                              src={value.image}
                              alt=""
                              style={{
                                maxWidth: "196px",
                                maxHeight: "196px",
                                margin: "auto",
                              }}
                            />
                          </figure>
                        </Link>
                        <figcaption className="w-100 ps-0">
                          <h6>{value.title}</h6>
                          <span className="author-name">
                            {translate("by")} {value?.store?.name}
                          </span>
                          <span className="delivery-date">
                            {translate("delivered_on")}: {value.delivered_date}
                          </span>
                          {/* <span className="author-name">Item type: Hardcover</span> */}

                          <small className="in-stock my-3 d-block">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/check-stock.svg`}
                              className="me-2"
                              alt="In Stock"
                            />
                            {translate("in_stock")}
                          </small>

                          <div className="product-rating">
                            {[...Array(parseInt(value?.rating))].map((x, i) => (
                              <img
                                key={i}
                                src={`${process.env.REACT_APP_BASE_URL}/assets/img/fav-star.svg`}
                                alt="Product Rating"
                              />
                            ))}
                            <span className="py-2">
                              {" "}
                              {value.reviewer} {translate("reviews")}
                            </span>
                          </div>
                        </figcaption>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3} className="p-0">
                  <div className="inclusiv-main">
                    <h2 className="mt-2 inclusive-text mb-4">
                      <span>{translate("inc_taxes")}:</span>
                      {value.sales_price}
                      {value.mrp > value.sales_price ? (
                        <small className="d-block">
                          {appSettings.currency_symbol}
                          {value.mrp} Save {appSettings.currency_symbol}
                          {value.mrp - value.sales_price} (
                          {percentMech(
                            ((value.mrp - value.sales_price) / value.mrp) * 100
                          )}
                          %)
                        </small>
                      ) : null}
                    </h2>
                    <div>
                      <CartButton
                        id={value.id}
                        className="mb-2 w-100"
                        combinations={value?.remain_stock[0]?.combinations}
                        quantity={1}
                        buy={true}
                        type="max"
                      ></CartButton>

                      <CartButton
                        id={value.id}
                        className="w-100"
                        combinations={value?.remain_stock[0]?.combinations}
                        quantity={1}
                        buy={false}
                        type="max"
                      ></CartButton>
                    </div>
                  </div>
                </Col>
              </>
            );
          else if (value.sales_price > 0) {
            return (
              <>
                <Col lg={9} className="position-relative" key={index}>
                  {value.is_bestseller ? (
                    <span className="seller-tag">{translate("best_seller")}</span>
                  ) : null}
                  <div className="orders-list productdesc-order list-view-order mt-4 mb-3">
                    <ul className="m-0 p-0 list-unstyled prdesc-li">
                      <li className="d-flex">
                        <Link to={`/product/${value.id}`}>
                          <figure
                            className="m-0 "
                            style={{
                              minWidth: "196px",
                              minHeight: "196px",
                              display: "flex",
                            }}
                          >
                            <img
                              src={value.image[0].split(",")[0]}
                              alt=""
                              style={{
                                maxWidth: "196px",
                                maxHeight: "196px",
                                margin: "auto",
                              }}
                            />
                          </figure>
                        </Link>
                        <figcaption className="w-100 ps-0">
                          <h6>{value.title}</h6>
                          <small className="in-stock my-3 d-block">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/assets/img/check-stock.svg`}
                              className="me-2"
                              alt="In Stock"
                            />
                            {translate("in_stock")}
                          </small>
                        </figcaption>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3} className="p-0">
                  <div className="inclusiv-main">
                    <h2 className="mt-2 inclusive-text mb-4">
                      <span>{translate("inc_taxes")}:</span>
                      {value.sales_price}
                      {value.mrp > value.sales_price ? (
                        <small className="d-block">
                          {appSettings.currency_symbol}
                          {percentMech(value.mrp)} Save {appSettings.currency_symbol}
                          {percentMech(value.mrp - value.sales_price)} (
                          {percentMech(
                            ((value.mrp - value.sales_price) / value.mrp) * 100
                          )}
                          %)
                        </small>
                      ) : null}
                    </h2>
                    <div>
                      <CartButton
                        id={value.id}
                        className="mb-2 w-100"
                        combinations={value?.remain_stock[0]?.combinations}
                        quantity={1}
                        buy={true}
                        type="max"
                      ></CartButton>

                      <CartButton
                        id={value.id}
                        className="w-100"
                        combinations={value?.remain_stock[0]?.combinations}
                        quantity={1}
                        buy={false}
                        type="max"
                      ></CartButton>
                    </div>
                  </div>
                </Col>
              </>
            );
          }
          else return <React.Fragment key={index}></React.Fragment>
        })}
      </Row>
    </div>
  );
};

export default ProductListing;
