import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";

import { Link, useHistory, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import { getAppSettings, getAuth, getUser } from "../helper/auth";
import { getInvoice, getOrders } from "../helper/request";

import { translate } from "../helper/translate";

import moment from "moment";
let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const OrderReturn = () => {
  const [orderData, setOrderData] = useState([]);
  const loadOrder = async () => {
    let res = await getOrders();
    setOrderData(res);
  };

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    if (status) {
      if (status === "failed") {
        toast.error(translate("order_pay_failed"));
      }
      if (status === "success") {
        toast.success(translate("order_pay_success"));
      }
      queryParams.delete("status");
      history.replace({
        search: queryParams.toString(),
      });
    }
    loadOrder();
  }, []);

  const [showModal, setShowModal] = useState(false);
  //ToDo: Niharika unused variable
  const [viewData, setViewData] = useState([]);
  const handleClose = () => {
    setShowModal(false);
  };
  //ToDo: Niharika unused variable
  const trackOrder = (data) => {
    setShowModal(true);
    setViewData(data);
  };

  const tracking_status = [
    {
      key: 0,
      value: "Payment Pending",
      title: "Payment Pending",
      body: "Your Order waiting for payment",
    },
    {
      key: 1,
      value: "Ordered",
      title: "Order Paid",
      body: "Your Order confirmed successfully",
    },
    {
      key: 2,
      value: "On the way",
      title: "Order On the way",
      body: "Your Order on the way.",
    },
    {
      key: 3,
      value: "Delivered",
      title: "Order delivered",
      body: "Your delivered successfully.",
    },
  ];
  const getTrackingStatus = (key, type) => {
    let title = "";
    tracking_status.map((value, index) => {
      if (value.key === key) {
        title = value[type];
      }
    });
    return title;
  };

  const downloadInvoice = async (invoice_id, order_no) => {
    let lqueue = [...loadingQueue];
    lqueue.push(invoice_id);
    setLoadingQueue(lqueue);

    let res = await getInvoice(invoice_id);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${order_no}.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    let rlqueue = [...loadingQueue];
    lqueue.splice(rlqueue.indexOf(invoice_id), 1);
    setLoadingQueue(rlqueue);
  };

  const [loadingQueue, setLoadingQueue] = useState([]);

  const checkLoading = (id) => {
    let lqueue = [...loadingQueue];
    if (lqueue.indexOf(id) > -1) {
      return true;
    } else {
      return false;
    }
  };
  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      {/* order-bredcum */}

      <section className="py-3 order-breadcum">
        <Container>
          <Row>
            <Col lg={5}>
              <div className="order-history-bredcum d-flex align-items-center">
                <div className="common-heading me-sm-3 me-2">
                  <h2 className="m-0">{translate("your_orders")} </h2>
                </div>

                {/* <ul className="p-0 m-0 list-unstyled">
                  <li>
                    <a href="#" className="order">
                      Order
                    </a>
                  </li>
                  <li>
                    <a href="#">Order History</a>
                  </li>
                  <li>
                    <a href="#">Cancelled Order</a>
                  </li>
                </ul> */}
              </div>
            </Col>
            {/* <Col lg={7}>
              <Form className="d-flex align-items-center search-order">
                <Form.Control type="text" placeholder="Search all Orders" />
                <div className="past-order">
                  <Button variant="primary" type="submit">
                    Search
                  </Button>

                  <Form.Select aria-label="Default select example">
                    <option>Past 6 months</option>
                    <option value="1">Past 4 months</option>
                    <option value="2">Past 2 months</option>
                    <option value="3">Past 3 months</option>
                  </Form.Select>
                </div>
              </Form>
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* order-bredcum */}

      {/* shopping-cart */}

      <section className="shoping-cart-sec order-info-sec py-sm-4 py-2">
        <Container>
          {orderData.length ? (
            <Form>
              <div className="table-responsive">
                <Table>
                  <tbody>
                    {orderData.map((value, index) => {
                      value.delivered_date = moment()
                        .add(parseInt(value.items[0].delivered_day), "days")
                        .format("LL");
                      value.order_date = moment(
                        value.created_date * 1000
                      ).format("LL");
                      return (
                        <tr key={index}>
                          <td>
                            <ul className="p-0 m-0 list-unstyled">
                              <li className="position-relative mb-2">
                                <div className="orders-list">
                                  <ul className="m-0 p-0 list-unstyled">
                                    <li className="d-flex">
                                      <figure
                                        className="m-0"
                                        style={{ display: "flex" }}
                                      >
                                        <img
                                          src={value.items[0].image}
                                          alt=""
                                          style={{ margin: "auto" }}
                                        />
                                      </figure>
                                      <figcaption>
                                        <h6>{value.items[0].title}</h6>
                                        <span className="author-name">
                                          by {value.store.name}
                                        </span>
                                        {value.status ? (
                                          <span className="delivery-date">
                                            {translate("delivered_on")}:{" "}
                                            {value.delivered_date}
                                          </span>
                                        ) : (
                                          <span className="delivery-date">
                                            {translate("pay_failed")}
                                          </span>
                                        )}

                                        <h2>
                                          <span>
                                            {" "}
                                            {translate("order_amount")} :
                                          </span>
                                          {appSettings.currency_symbol}
                                          {value.pay_amount}
                                        </h2>
                                      </figcaption>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <div className="order-all-info px-2">
                              <ul className="mb-3 p-0 order-info">
                                <li>
                                  {translate("order")}# {value.order_no}
                                </li>
                                <li>
                                  {translate("order_date")}: {value.order_date}
                                </li>
                                {value.status ? (
                                  <li>
                                    {translate("order_status")}:{" "}
                                    {getTrackingStatus(value.status, "title")}
                                  </li>
                                ) : null}
                                {value.tracking_number ? (
                                  <li>
                                    {translate("trancking_number")}:{" "}
                                    {value.tracking_number}
                                  </li>
                                ) : null}

                                {/* <li>Ship to: Aubrey laos</li> */}
                              </ul>

                              {value.status
                                ? null //<a href="#">Invoice</a>
                                : null}
                            </div>
                          </td>
                          <td className="align-middle">
                            <ul className="m-0 p-0 list-unstyled track-package">
                              <li>
                                <Link to={`./order/${value.id}`}>
                                  {translate("view_order_detials")}
                                </Link>
                              </li>
                              {value.status && value.tracking_url ? (
                                <li>
                                  <a href={value.tracking_url} target="_blank">
                                    {translate("track_package")}
                                  </a>
                                </li>
                              ) : null}
                              {/* {
                                  (value.status&&value.sevdesk_invoice_id)?
                                  <li>
                                    {
                                      checkLoading(value.sevdesk_invoice_id)?
                                        <span>Loading..</span>
                                      :
                                        <a href="javascript:;" onClick={()=>{downloadInvoice(value.sevdesk_invoice_id,value.order_no)}}  >Download Invoice</a>
                                  
                                    }
                                    
                                    </li>
                                  :null
                                } */}

                              {/* <li>
                                  <a href="#">Review Product</a>
                                </li>
                                <li>
                                  <a href="#">Delivery Feedback</a>
                                </li> */}
                            </ul>
                          </td>
                          {/* <td className="align-middle">
                              <Button
                                variant="primary"
                                type="submit"
                                className="light-btn"
                              >
                                Buy Again
                              </Button>
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Form>
          ) : (
            <Row>
              <Col
                className="flex-wrap card"
                style={{
                  padding: "40px",
                }}
              >
                {translate("order_not_found")}
              </Col>
            </Row>
          )}
        </Container>
      </section>

      {/* shopping-cart */}

      {/* history-sec */}

      {/* <section className="history-sec mb-5">
        <Container>
          <div className="common-heading mb-4">
            <h2 className="green-text">Product related to this item </h2>
          </div>
          <div className="bg-white">
          <Productlist className="mb-3" />
          </div>
        </Container>
      </section> */}

      {/* history-sec */}

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        className="comon-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">{translate("track_order")}</Modal.Title>:
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </div>
  );
};

export default OrderReturn;
