import { getLanguage } from "./auth";
let en = require("../languages/en.json");
let de = require("../languages/de.json");

function format(str, arr) {
  return str.replace(/{(\d+)}/g, function (_, m) {
    let n = arr[--m];
    return n ? n : "";
  });
}

export function translate(key, data = []) {
  let lang = getLanguage();
  let selected_lang = en;
  if (lang === "de") {
    selected_lang = de;
  }

  if (selected_lang[key]) {
    let str = selected_lang[key];
    return format(str, data);
  } else {
    return key.toString();
  }
}
