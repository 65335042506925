import { toast } from "react-toastify";

export function resHandler(response) {
  let output = { success: false, data: [] };
  try {
    if (response.status === 200) {
      //success
      if (response.data.status === "success") {
        output.success = true;
        output.data = response.data.data;
        if (response.data.message !== "order placed successfully")
          toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } else if (response.status === 400) {
      //validation
      output.data = response.data.data;
      toast.error(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(response.data.message);
  }
  return output;
}

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}

export function percentMech(percent) {
  return Math.round(percent);
}
