import React, { useEffect, useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { getSupportReasons, submitSupport } from "../../helper/request";
import { resHandler } from "../../helper/response";
import { translate } from "../../helper/translate";
import { scrollToTop } from "../../helper/general";

import "./footer.css";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [optiondData, setOptiondData] = useState([]);
  const feedbackDefault = {
    name: "",
    email: "",
    reason: "",
    description: "",
  };
  const [feedBackRow, setFeedbackRow] = useState(feedbackDefault);
  const openFeedback = () => {
    setFeedbackRow(feedbackDefault);
    setShow(true);
  };
  const feedbackSubmit = async (e) => {
    e.preventDefault();

    let res = await submitSupport({ ...feedBackRow });
    let output = resHandler(res);
    if (output.success) {
      handleClose();
    }
  };
  const feedbackHandleChange = (e) => {
    let data = { ...feedBackRow };
    data[e.target.name] = e.target.value;
    setFeedbackRow(data);
  };

  useEffect(() => {
    const asyncBehaviorEnabler = async () => {
      let reasons = await getSupportReasons();
      setOptiondData(reasons);
    };
    asyncBehaviorEnabler();
  }, []);

  return (
    <>
      <div className="backtop text-center">
        <a href="#" onClick={scrollToTop}>Back to Top</a>
      </div>
      <footer>
        <Container>
          <Row>
            <Col xs={12} sm={6} lg={3}>
              <a href="/about-us" className="d-block heading">
                <p>{translate("footer.about_us")}</p>
              </a>
              <a href="/shipping-terms" className="d-block heading">
                <p>{translate("footer.shipping_terms")}</p>
              </a>
              <a href="/faqs" className="d-block heading">
                <p>{translate("footer.faq")}</p>
              </a>
              <p onClick={openFeedback} className="d-block mt-2 links-footer">
                {translate("feedback")}
              </p>
            </Col>

            <Col xs={12} sm={6} lg={3}>
              <a href="/imprint" className="d-block heading">
                <p>{translate("footer.imprint")}</p>
              </a>
              <a href="/data-protection" className="d-block heading">
                <p>{translate("footer.data_policy")}</p>
              </a>
              <a href="/terms-and-conditions" className="d-block  heading">
                <p>{translate("footer.terms_and_conditions")}</p>
              </a>
              <a href="/refund-policy" className="d-block heading">
                <p>{translate("footer.refund_policy")}</p>
              </a>
            </Col>

            {/*<Col>
              <div className="">
                <a href="/" className="d-block text-center">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/assets/img/footer-logo.svg`}
                    alt=""
                  />
                </a>
              </div>

  </Col>*/}

            <Col xs={12} sm={6} lg={3}>
              <div className="">
                <a href="/" className="d-block heading">
                  <h5>{translate("footer.contact_us")}</h5>
                </a>
              </div>
              <address className="d-block heading">
                Waldecker Straße 19
                <br />
                60433 Frankfurt am Main
                <br />
                <abbr title="Phone">P:</abbr> +491724182490
                <br />
                <abbr title="Email">E:</abbr> info@amzio.de
              </address>
              <div className="footer-inline">
                <a
                  className="btn"
                  style={{ background: "#3b5998" }}
                  href="#!"
                  role="button"
                >
                  <i className="fab fa-facebook-f heading"></i>
                </a>

                <a
                  className="btn"
                  style={{ background: "#dd4b39" }}
                  href="#!"
                  role="button"
                >
                  <i className="fab fa-google heading"></i>
                </a>

                <a
                  className="btn"
                  style={{ background: "#ec4a89" }}
                  href="#!"
                  role="button"
                >
                  <i className="fab fa-instagram heading"></i>
                </a>
              </div>
            </Col>

            <Col xs={12} sm={6} lg={3}>
              <div className="mb-4">
                <h5 className="d-block mt-2 heading">
                  {translate("footer.delivery_options")}
                </h5>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/dhl_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/hermes_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/dpd_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
              </div>
              <div className="">
                <h5 className="d-block mt-2 heading">
                  {translate("footer.payment_options")}
                </h5>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/paypal_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/stripe_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/assets/img/klarna_footer.png`}
                  alt=""
                  className="me-2 footer-image"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      {/*<div className="copyright">
        <span>{translate("footer_text", ["2005-2022"])}</span>
        </div>*/}

      <Modal show={show} onHide={handleClose} className="comon-modal">
        <Modal.Header closeButton>
          <Modal.Title>Feedback Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={feedbackSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={feedBackRow.name}
                name="name"
                onChange={(event) => feedbackHandleChange(event)}
                placeholder="Enter email"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={feedBackRow.email}
                name="email"
                onChange={(event) => feedbackHandleChange(event)}
                placeholder="Enter email"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Reason</Form.Label>
              <Form.Select
                value={feedBackRow.reason}
                name="reason"
                onChange={(event) => feedbackHandleChange(event)}
                required
              >
                {optiondData.map((valueA, indexA) => (
                  <option key={indexA} value={valueA.key}>
                    {valueA.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Descriptions</Form.Label>
              <Form.Control
                as="textarea"
                value={feedBackRow.description}
                name="description"
                onChange={(event) => feedbackHandleChange(event)}
                required
                rows={5}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
