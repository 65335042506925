import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slidercommon from "../components/Slidercommon";

import { getAuth, getUser } from "../helper/auth";
import {
  getBanners,
  getHomeFilters,
  getRecommendations,
} from "../helper/request";
import { translate } from "../helper/translate";

let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const Index = () => {
  const [banners, setBanners] = useState([]);
  const loadBanners = async () => {
    let data = await getBanners();
    setBanners(data);
  };
  const [filters, setFilters] = useState([]);
  const loadFilters = async () => {
    let data = await getHomeFilters();
    setFilters(data);
  };

  const [recommendations, setRecommendations] = useState([]);
  const loadRecommensations = async () => {
    let data = await getRecommendations();
    setRecommendations(data);
  };

  useEffect(() => {
    loadBanners();
    loadFilters();
    if (authData) {
      loadRecommensations();
    }
  }, []);

  return (
    <>
      {/* banner-sec */}

      <section className="banner-sec">
        <a href="/search/5ccf025ad441e46dd8684f0204f76b79">
          <img src="./assets/img/home-banner.jpg" alt="" className="w-100" />
        </a>
      </section>

      {/* banner-sec */}

      {/* discount-sec */}

      <section className="dis-sec">
        <Container>
          <Row>
            {banners.map((value, index) => (
              <Col lg={3} sm={6} className="mb-3" key={index + "_BANNERS"}>
                <Link to={`search/filter-${value.id}`}>
                  <figcaption className="position-relative">
                    <div className="dis-box">
                      <h2>
                        {value.title} <span>{value.sub_title}</span>
                      </h2>
                    </div>
                    <figure className="m-0">
                      <img
                        src={value.image}
                        alt=""
                        className="w-100"
                        style={{ maxHeight: "264px", minHeight: "264px" }}
                      />
                    </figure>
                    <a href={`search/filter-${value.id}`}>
                      {translate("see_more")}
                    </a>
                  </figcaption>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* discount-sec */}

      {/* slider-sec */}

      <section className="top-deal-slider mb-3">
        <Container>
          <div className="heading text-center">
            <span>{translate("find_more_cate")}</span>
            <h2>{translate("find_more_sub")}</h2>
          </div>
        </Container>
      </section>

      {filters.map((value, index) => {
        return value.products.length ? (
          <section className="top-deal-slider mb-3" key={index + "_offers"}>
            <Container>
              <div className="top-del">
                <div className="slider-heading">
                  <h2 className="me-2">{value.title}</h2>
                  {value.products.length > 10 ? (
                    <Link to={`search/filter-${value.id}`}>
                      {translate("see_more")}
                    </Link>
                  ) : null}
                </div>
                <Slidercommon products={value.products} />
              </div>
            </Container>
            <br />
          </section>
        ) : null;
      })}

      {/* slider-sec */}

      {/* offer-dis-sec */}
      {recommendations.length ? (
        <>
          <section className="offer-dis-sec mb-4 mt-sm-5 mt-4">
            <Container>
              <div className="top-del">
                <div className="heading">
                  <span>{translate("recommended_items")}</span>
                  <h2>{translate("recommended_items_sub")}</h2>
                </div>
              </div>
            </Container>
          </section>
          <section className="top-deal-slider mb-3">
            <Container>
              <div className="top-del">
                <Slidercommon products={recommendations} />
              </div>
            </Container>
            <br />
          </section>
        </>
      ) : null}

      {/* offer-dis-sec */}

      {/* recommand-item-sec */}

      {/* <section className="recommand-sec py-sm-4 py-2">
          <Container>
            <div className="heading text-center">
              <span>Recommended Items</span>
              <h2>Suggested According to history.</h2>
            </div>
            <Row>
              <Col lg={6} sm={6}>
                <figure className="m-0 position-relative">
                  <img
                    src="./assets/img/recommand-item.png"
                    alt=""
                    className="w-100"
                  />

                  <h2>Up to 50% off | Top Offers from Top Brands</h2>
                </figure>
              </Col>

              <Col lg={6} sm={6}>
                <figure className="position-relative">
                  <img
                    src="./assets/img/recommand-item2.png"
                    alt=""
                    className="w-100"
                  />

                  <figcaption>
                    <span>Up to 50% off | Top Offers from Top Brands </span>
                  </figcaption>
                </figure>

                <figure className="position-relative fig2">
                  <img
                    src="./assets/img/recommand-item3.png"
                    alt=""
                    className="w-100"
                  />
                  <figcaption>
                    <span>Up to 50% off | Top Offers from Top Brands </span>
                  </figcaption>
                </figure>
              </Col>
            </Row>
          </Container>
        </section> */}

      {/* recommand-item-sec */}
    </>
  );
};
export default Index;
