import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./assets/css/App.css";
import "./assets/css/media.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AccountList from "./pages/AccountList";
import Addresses from "./pages/Addresses";
import Cart from "./pages/Cart";
import CategoryGrid from "./pages/CategoryGrid";
import Checkout from "./pages/Checkout";
import Index from "./pages/Index";
import KlarnaPay from "./pages/KlarnaPay";
import NotificationList from "./pages/NotificationList";
import OrderDetails from "./pages/OrderDetails";
import OrderReturn from "./pages/OrderReturn";
import Productdesc from "./pages/Productdesc";
import StripePay from "./pages/StripePay";
import DataProtection from "./pages/static/DataProtection/index";
import Imprint from "./pages/static/Imprint/index";
import RefundPolicy from "./pages/static/RefundPolicy/index";
import TermsAndCondition from "./pages/static/TermsAndCondition/index";

import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setupAxios } from "./helper/auth";

import { UserContext } from "./helper/contextApi";
import { getCartCount } from "./helper/request";
import ShippingTerms from "./pages/static/ShippingTerms";
import FAQ from "./pages/static/FAQ";
import AboutUs from "./pages/static/AboutUs";
setupAxios(axios);
function App() {
  const loadCartCount = async () => {
    let cn = await getCartCount();
    // if(cn.staus === 'success'){
    setGlocalData(prevState => ({
      ...prevState,
      cart_count: cn,
    }))
    // }
  };
  useEffect(() => {
    loadCartCount();
  }, []);
  const [globalData, setGlocalData] = useState({
    cart_count: 1,
    load_cart: loadCartCount,
  });
  return (
    <div className="App">
      <UserContext.Provider value={globalData}>
        <BrowserRouter>
          <Header />
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Index />
            </Route>
            <Route exact path="/account">
              <AccountList />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/orders">
              <OrderReturn />
            </Route>
            <Route exact path="/order/:id">
              <OrderDetails />
            </Route>
            <Route exact path="/product/:id">
              <Productdesc />
            </Route>
            <Route exact path="/checkout">
              <Checkout />
            </Route>
            <Route exact path="/notifications">
              <NotificationList />
            </Route>
            <Route exact path="/addresses">
              <Addresses />
            </Route>
            <Route exact path="/search/:id1?/:id2?">
              <CategoryGrid />
            </Route>
            <Route exact path="/pay/stripe/:order_ids">
              <StripePay />
            </Route>
            <Route exact path="/pay/klarna/:order_ids">
              <KlarnaPay />
            </Route>
            <Route exact path="/imprint">
              <Imprint />
            </Route>
            <Route exact path="/data-protection">
              <DataProtection />
            </Route>
            <Route exact path="/terms-and-conditions">
              <TermsAndCondition />
            </Route>
            <Route exact path="/refund-policy">
              <RefundPolicy />
            </Route>
            <Route exact path="/shipping-terms">
              <ShippingTerms />
            </Route>
            <Route exact path="/faqs">
              <FAQ />
            </Route>
            <Route exact path="/about-us">
              <AboutUs />
            </Route>
          </Switch>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

const ScrollToTop = () => {
  const router = useLocation() ?? "";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.pathname]);
  return null;
};

export default App;
