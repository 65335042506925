import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import { translate } from "../helper/translate";

const Slidercommon = (props) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 4.2,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const [products, setProducts] = useState(
    props.products ? props.products : []
  );
  useEffect(() => {
    setProducts(props.products);
  });
  return (
    <div className="common-slider">
      <Slider {...settings}>
        {products.map((value, index) => (
          <div className="custom-relative" key={index}>
            {value.is_bestseller ? (
              <span className="seller-tag">{translate("best_seller")}</span>
            ) : null}

            <Link to={`product/${value.id}`}>
              <figure
                style={{
                  minWidth: "166px",
                  minHeight: "166px",
                  display: "flex",
                }}
              >
                <img
                  src={value.image}
                  alt=""
                  style={{
                    maxWidth: "166px",
                    maxHeight: "166px",
                    margin: "auto",
                  }}
                />
              </figure>
              <figcaption>
                <h2>{value.title}</h2>
              </figcaption>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slidercommon;
