import { Container } from "react-bootstrap";

const ShippingTerms = () => {
  return (
    <Container className="pt-4">
      <h2 className="account-heading">Zahlung und Versand</h2>
      <p>Es gelten folgende Bedingungen:</p>
      <p>Die Lieferung erfolgt nur im Inland (Deutschland).</p>
      <p>
        <h6>Versandkosten</h6> (inklusive gesetzliche Mehrwertsteuer)
      </p>
      <p>Wir berechnen die Versandkosten pauschal mit 3,99 € pro Bestellung.</p>
      <p>Ab einem Bestellwert von 50,00 € liefern wir versandkostenfrei.</p>

      <h2>Lieferfristen</h2>
      <p>
      Soweit im jeweiligen Angebot keine andere Frist angegeben ist, erfolgt die Lieferung der Ware im Inland (Deutschland) innerhalb von 3 -
5 Werktagen nach Vertragsschluss (bei vereinbarter Vorauszahlung nach dem Zeitpunkt Ihrer Zahlungsanweisung). <br /> 
Beachten Sie, dass an Sonn- und Feiertagen keine Zustellung erfolgt. <br />
Haben Sie Artikel mit unterschiedlichen Lieferzeiten bestellt, versenden wir die Ware in einer gemeinsamen Sendung, sofern wir keine
abweichenden Vereinbarungen mit Ihnen getroffen haben. Die Lieferzeit bestimmt sich in diesem Fall nach dem Artikel mit der längsten
Lieferzeit den Sie bestellt haben.
      </p>

      <h2>Akzeptierte Zahlungsmöglichkeiten</h2>

      <h6>Über PayPal Checkout:</h6>
      <p>
        <ul>
          <li>Zahlung per PayPal</li>
          <li>Zahlung per PayPal Express</li>
          <li>Zahlung per Kreditkarte</li>
          <li>Zahlung per SEPA-Lastschrift</li>
          <li>Zahlung per "Später bezahlen"</li>
          <li>Zahlung per Ratenkauf</li>
          <li>Zahlung per Sofort/Sofortüberweisung</li>
          <li>Zahlung per giropay</li>
          <li>Zahlung per Rechnung (über Ratepay)</li>
        </ul>
      </p>

      <h6>Über PayPal:</h6>
      <p>
        <ul>
          <li>Zahlung per PayPal</li>
          <li>Zahlung per PayPal Express</li>
          <li>Zahlung per Kreditkarte</li>
          <li>Zahlung per SEPA-Lastschrift</li>
          <li>Zahlung per "Später bezahlen"</li>
        </ul>
      </p>

      <h6>Über Stripe:</h6>
      <p>
        <ul>
          <li>Zahlung per Sofort/Sofortüberweisung</li>
          <li>Zahlung per SEPA-Lastschrift (über Klarna)</li>
          <li>Zahlung per Kreditkarte (über Klarna)</li>
          <li>Zahlung per Rechnung (über Klarna)</li>
          <li>Zahlung per Ratenkauf (über Klarna)</li>
          <li>Zahlung per Kreditkarte</li>
          <li>Zahlung per giropay</li>
          <li>Zahlung per Apple Pay</li>
          <li>Zahlung per Google Pay</li>
          <li>Zahlung per SEPA-Lastschrift</li>
        </ul>
      </p>
      <p>Bei Fragen finden Sie unsere Kontaktdaten im Impressum.</p>
    </Container>
  );
};

export default ShippingTerms;
