import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Productlist from "../components/Productlist";

import { Oval } from "react-loader-spinner";
import {
  getAppSettings,
  getAuth,
  getUser,
  setCurrentUser,
} from "../helper/auth";
import {
  getOrders,
  getProfile,
  getRecommendations,
  updateProfile,
} from "../helper/request";
import { resHandler } from "../helper/response";

import { useHistory } from "react-router-dom";
import { translate } from "../helper/translate";

import moment from "moment";
let authData = getAuth();
let userData = authData ? getUser() : false;

const AccountList = () => {
  const history = useHistory();

  const [lastOrderData, setLastOrderData] = useState({});
  const loadOrders = async () => {
    let res = await getOrders();
    for (let i = 0; i < res.length; i++) {
      if (res[i].status) {
        res[i].delivered_date = moment()
          .add(parseInt(res[i].items[0].delivered_day), "days")
          .format("LL");
        setLastOrderData(res[i]);
        break;
      }
    }
  };

  const [recommendations, setRecommendations] = useState([]);
  const loadRecommensations = async () => {
    let data = await getRecommendations();
    setRecommendations(data);
  };

  useEffect(() => {
    loadOrders();
    loadRecommensations();
  }, []);

  const gotoOrders = () => {
    history.push("/orders");
  };
  const gotoAddress = () => {
    history.push("/addresses");
  };

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const deafultProfile = {
    first_name: "",
    last_name: 0,
    mobile: "",
  };
  const [profileRow, setProfileRow] = useState(deafultProfile);
  const handleChange = (e) => {
    let data = { ...profileRow };
    data[e.target.name] = e.target.value;
    setProfileRow(data);
  };
  const [validationData, setValidationData] = useState([]);
  const checkValidationError = (key, type) => {
    let res = false;
    for (let i in validationData) {
      if (key === validationData[i].param) {
        if (type === "msg") {
          res = validationData[i].msg;
        } else {
          res = true;
        }
      }
    }
    return res;
  };
  const [buttonloader, setButtonloader] = useState(false);

  const openEditModal = async () => {
    let data = { ...profileRow };
    data.first_name = userData.first_name;
    data.last_name = userData.last_name;
    data.mobile = userData.mobile;
    setProfileRow(data);
    setShowModal(true);
  };
  const updateProfileAction = async () => {
    setButtonloader(true);
    let data = { ...profileRow };
    let res = await updateProfile(data);
    let output = resHandler(res);
    if (output.success) {
      setShowModal(false);
    } else {
      if (output.data.length) {
        //validation
        setValidationData(output.data);
      }
    }
    let profile_data = await getProfile();
    setCurrentUser(profile_data);

    authData = getAuth();
    userData = authData ? getUser() : false;

    setButtonloader(false);
  };

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);
  return (
    <div>
      {/* account-detail */}

      <section className="account-detail-sec">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="account-detail">
                <h2 className="account-heading">{translate("your_account")}</h2>
                <div className="d-flex justify-content-between account-detail-flex">
                  <ul className="m-0 p-0 list-unstyled">
                    <li>
                      {translate("account_name")}:{" "}
                      <small>
                        {userData.first_name} {userData.last_name}
                      </small>
                    </li>
                    <li>
                      {translate("email")}: <small>{userData.email}</small>
                    </li>
                  </ul>

                  <ul className="m-0 p-0 list-unstyled">
                    <li>
                      {translate("mobile_number")}:{" "}
                      <small>{userData.mobile ? userData.mobile : "NA"}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={5}>
              {Object.keys(lastOrderData).length ? (
                <div className="orders-list">
                  <h2 className="account-heading">Last Order</h2>

                  <ul className="m-0 p-0 list-unstyled">
                    <li className="d-flex">
                      <figure className="m-0">
                        <img src={lastOrderData.items[0].image} alt="" />
                      </figure>
                      <figcaption>
                        <h6>
                          <a href="#">{lastOrderData.items[0].title}</a>
                        </h6>
                        <span className="author-name">
                          {translate("by")} {lastOrderData.store.name}
                        </span>
                        <span className="delivery-date">
                          {translate("delivered_on")}:{" "}
                          {lastOrderData.delivered_date}
                        </span>
                        <h2>
                          <span>{translate("order_amount")}:</span>
                          {appSettings.currency_symbol}
                          {lastOrderData.pay_amount}
                        </h2>
                      </figcaption>
                    </li>
                  </ul>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>

      {/* account-detail */}

      {/* order-info */}

      <section className="order-combo py-lg-5 py-4">
        <Container>
          <Row className="justify-content-center">
            <Col
              lg={4}
              sm={4}
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={gotoOrders}
            >
              <div className="combo-detail w-100">
                <figure className="m-0">
                  <img src="./assets/img/order-combo1.jpg" alt="" />
                </figure>
                <figcaption>
                  <h2>{translate("your_orders")} </h2>
                  <span>{translate("orders_title")}</span>
                </figcaption>
              </div>
            </Col>

            <Col
              lg={4}
              sm={4}
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={openEditModal}
            >
              <div className="combo-detail w-100">
                <figure className="m-0">
                  <img src="./assets/img/order-combo2.jpg" alt="" />
                </figure>
                <figcaption>
                  <h2>{translate("orders_login")}</h2>
                  <span>{translate("orders_sub")}</span>
                </figcaption>
              </div>
            </Col>

            <Col
              lg={4}
              sm={4}
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={gotoAddress}
            >
              <div className="combo-detail w-100">
                <figure className="m-0">
                  <img src="./assets/img/delivery-step.jpg" alt="" />
                </figure>
                <figcaption>
                  <h2>{translate("addressess")}</h2>
                  <span>{translate("edit_add_address")}</span>
                </figcaption>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* order-info */}

      {/* history-sec */}

      {recommendations.length ? (
        <section className="history-sec mb-5">
          <Container>
            <div className="common-heading mb-4">
              <h2>{translate("browse_history")}</h2>
            </div>
            <Productlist products={recommendations} />
          </Container>
        </section>
      ) : null}

      <Modal show={showModal} onHide={handleClose} className="comon-modal">
        <Modal.Header closeButton>
          <Modal.Title className="h6">{translate("edit_profile")}</Modal.Title>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("first_name")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("first_name")}
                    onChange={(event) => handleChange(event)}
                    name="first_name"
                    type="text"
                    value={profileRow.first_name}
                    placeholder={translate("enter_first_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("first_name", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("last_name")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("last_name")}
                    onChange={(event) => handleChange(event)}
                    name="last_name"
                    type="text"
                    value={profileRow.last_name}
                    placeholder={translate("enter_last_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("last_name", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{translate("mobile_number")}</Form.Label>
                  <Form.Control
                    isInvalid={checkValidationError("mobile")}
                    onChange={(event) => handleChange(event)}
                    name="mobile"
                    type="text"
                    value={profileRow.mobile}
                    placeholder={translate("enter_mobile_number")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {checkValidationError("mobile", "msg")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {!buttonloader ? (
              <Button variant="secondary" onClick={() => updateProfileAction()}>
                Update
              </Button>
            ) : (
              <Button variant="outline-primary d-flex" size="sm">
                <Oval color="#4A5073" height={15} width={15} /> &nbsp;{" "}
                {translate("loading")}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      {/* history-sec */}
    </div>
  );
};

export default AccountList;
