import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Table } from "react-bootstrap";
import "../assets/css/StripeStyle.css";

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { useHistory, useParams } from "react-router-dom";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import { getMultipleOrders, stripeCharge } from "../helper/request";
import { resHandler } from "../helper/response";

let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const Cart = () => {
  const { order_ids } = useParams();

  const history = useHistory();

  const [orderData, setOrderData] = useState([]);
  const [totalPay, setTotalPay] = useState(0);
  const loadOrder = async () => {
    let orders = await getMultipleOrders({
      order_ids: order_ids.split(","),
    });
    let amount = 0;
    for (let i in orders) {
      amount += orders[i].pay_amount;
    }
    setTotalPay(amount);
    setOrderData(orders);
  };

  //ToDo: Niharika unused variable
  const gotoProceed = () => {
    history.push("/checkout");
  };

  useEffect(() => {
    loadOrder();
  }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);

  return (
    <div>
      <section className="shoping-cart-sec py-md-4 ">
        <Container>
          <div className="common-heading mb-4">
            <h2>Payment</h2>
          </div>
        </Container>
      </section>

      {(orderData.length ? orderData : []).map((valueB, indexB) => {
        return (
          <section className="shoping-cart-sec order-info-sec py-sm-4 py-2">
            <Container>
              <Form>
                <div className="table-responsive">
                  <h4>Order (#{valueB.order_no})</h4>
                  <h5>Store ({valueB.store.name})</h5>
                  <Table>
                    <tbody>
                      {valueB.items.map((value, index) => {
                        return (
                          <tr key={("Param" + indexB, index)}>
                            <td>
                              <ul className="p-0 m-0 list-unstyled">
                                <li className="position-relative mb-2">
                                  <div className="orders-list">
                                    <ul className="m-0 p-0 list-unstyled">
                                      <li className="d-flex">
                                        <figure className="m-0">
                                          <img src={value.image} alt="" />
                                        </figure>
                                        <figcaption>
                                          <h6>{value.title}</h6>
                                          <span className="author-name">
                                            Quantity : {value.quantity}
                                          </span>
                                          <span className="author-name">
                                            {appSettings.currency_symbol}
                                            {value.sales_price}
                                          </span>
                                        </figcaption>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </td>
                            <td>
                              <div
                                className="order-all-info px-2"
                                style={{ display: "grid" }}
                              >
                                <h6>Details</h6>
                                {value.combinations.map((valueA, indexA) => (
                                  <span className="author-name" key={indexA}>
                                    {valueA.key} : {valueA.title}
                                  </span>
                                ))}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                          <h5 style={{ paddingLeft: "30px" }}>
                            Delivery Charge : {appSettings.currency_symbol}
                            {valueB.delivery_charges}
                          </h5>
                        </td>
                        <td>
                          <h5 style={{ paddingLeft: "30px" }}>
                            Total : {appSettings.currency_symbol}
                            {valueB.pay_amount}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Form>
            </Container>
          </section>
        );
      })}

      <section className="shoping-cart-sec py-md-5 py-3 ">
        <Container>
          <Col lg={6} sm={12}>
            <Elements stripe={stripePromise}>
              <CheckoutForms amount={totalPay} />
            </Elements>
          </Col>

          {/* <Form >
              <Row>
                

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label> Name</Form.Label>
                    <Form.Control isInvalid={checkValidationError('name')} onChange={event=>handleChange(event)} value={addressRow.name} type="text" name="name" placeholder="Enter Name" />
                    <Form.Control.Feedback type="invalid">{checkValidationError('name','msg')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email"  isInvalid={checkValidationError('email')} onChange={event=>handleChange(event)} value={addressRow.email} name="email" placeholder="Enter Email" />
                    <Form.Control.Feedback type="invalid">{checkValidationError('email','msg')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="text" isInvalid={checkValidationError('amount')} onChange={event=>handleChange(event)} value={addressRow.pincode} name="amount" placeholder="Enter Amount" />
                    <Form.Control.Feedback type="invalid">{checkValidationError('amount','msg')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                
              </Row>

              
              { 
                !buttonloader?
                    <Button variant="secondary" onClick={()=>payAction()}>Pay</Button>
                  :
                  <Button variant="outline-primary d-flex"  size="sm"><Oval color="#4A5073" height={15} width={15} /> &nbsp; Loading</Button>
              } 
            </Form> */}
        </Container>
      </section>

      {/* shopping-cart */}

      {/* history-sec */}

      {/* <section className="history-sec mb-5">
        <Container>
          <div className="common-heading mb-4">
            <h2>
              Your saved Items <span>(5 Items)</span>{" "}
            </h2>
          </div>

          <div className="bg-white">
          <Productlist className="mb-3" />
          </div>
        </Container>
      </section> */}

      {/* history-sec */}
    </div>
  );
};

const CheckoutForms = (props) => {
  const { order_ids } = useParams();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  let amount = props.amount;

  const gotoOrders = () => {
    history.push("/orders");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements === null) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.error(result.error.message);
    } else {
      result.order_ids = order_ids.split(",");
      let res = await stripeCharge(result);
      let output = resHandler(res);
      if (output.success) {
        gotoOrders();
      }
    }
  };
  const [appSettings, setAppSettings] = useState({});
  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!stripe || !elements}
      >
        Pay ( {appSettings.currency_symbol}
        {amount} )
      </button>
    </form>
  );
};

export default Cart;
