import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import Productlist from "../components/Productlist";
import ProductListing from "../components/ProductListing";

import InfiniteScroll from "react-infinite-scroll-component";

import { getAppSettings, getAuth, getUser } from "../helper/auth";
import {
  getCategory,
  getCategoryDetails,
  getFilterDetails,
  getProducts,
  getProductsV2,
} from "../helper/request";
import { translate } from "../helper/translate";

let authData = getAuth();
//ToDo: Niharika unused variable
let userData = authData ? getUser() : false;

const CategoryGrid = () => {
  let { id1, id2 } = useParams();
  let location = useLocation();
  const defaultData = {
    parent_category_id: id1 ? (id1.indexOf("filter-") > -1 ? "" : id1) : "",
    category_id: id2 ? id2 : "",
    filter_id: id1
      ? id1.indexOf("filter-") > -1
        ? id1.split("filter-")[1]
        : ""
      : "",
  };

  const [parentCategory, setParentCategory] = useState({});
  const [category, setCategory] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({});

  const [allCategory, setAllCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [dynamicCategory, setDynamicCategory] = useState([]);

  const [grid, setGrid] = useState(true);

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [bigBuyProducts, setBigBuyProducts] = useState([]);
  //ToDo: Niharika unused variable
  const [limit, setLimit] = useState(12);
  const [sort, setSort] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  //ToDo: Niharika unused variable
  const [deliveryDays, setDeliveryDays] = useState(0);
  const [dynamicFilter, setDynamicFilter] = useState([]);
  const [seletedCategory, setSeletedCategory] = useState([]);
  const [seletedParentCategory, setSeletedParentCategory] = useState([]);
  const [page, setPage] = useState(0);

  const loadPageData = async () => {
    if (defaultData.parent_category_id) {
      let parentData = await getCategoryDetails(defaultData.parent_category_id);
      setParentCategory(parentData);
    }
    if (defaultData.category_id) {
      let catData = await getCategoryDetails(defaultData.category_id);
      setDynamicCategory(catData.custom_search_filters);
      setCategory(catData);
    }
    if (defaultData.filter_id) {
      let fData = await getFilterDetails(defaultData.filter_id);
      //setDynamicCategory(fData.custom_search_filters)
      setSelectedFilter(fData);
    }

    if (
      defaultData.category_id === "" &&
      defaultData.parent_category_id !== ""
    ) {
      //parent cat
      let pData = await getCategory(defaultData.parent_category_id);
      setSubCategory(pData);
    }

    if (
      defaultData.category_id === "" &&
      defaultData.parent_category_id === "" &&
      defaultData.filter_id === ""
    ) {
      let aData = await getCategory();
      setAllCategory(aData);
    }
    //loadProducts(true);
  };

  const hasMore = () => {
    return totalProducts > products.length
  };

  const loadProducts = async (clear = true) => {
    let my_last_id = 0;
    if (!clear) {
      my_last_id = products.length ? products[products.length - 1].id : 0;
    }
    //alert(my_last_id)
    const query = new URLSearchParams(location.search);
    let search_query = query.get("query") ? query.get("query") : "";
    let data = await getProductsV2(
      clear ? 0 : page,
      search_query,
      defaultData.parent_category_id,
      defaultData.category_id,
      limit,
      my_last_id,
      sort,
      minPrice,
      maxPrice,
      dynamicFilter,
      defaultData.filter_id,
      deliveryDays,
      null,
      seletedCategory,
      seletedParentCategory,
    );
    let new_data = [];
    if (clear) {
      new_data = data.records;
      setPage(1)
    } else {
      new_data = [...products].concat(data.records);
      setPage(page => page + 1)
    }
    setTotalProducts(data.total);

    setProducts(new_data);
  };

  useEffect(() => {
    //reset
    setParentCategory({});
    setCategory({});
    setSelectedFilter({});

    setAllCategory([]);
    setSubCategory([]);
    setDynamicCategory([]);
    setDynamicFilter([]);
    setSeletedCategory([]);
    setSeletedParentCategory([]);
    setTimeout(() => {
      loadPageData();
    });
  }, [location]);

  // useEffect(()=>{
  //     loadPageData();
  // },[]);

  //ToDo: Niharika check what is wrong
  useEffect(() => {
    loadProducts(true);
  }, [
    sort,
    seletedCategory,
    seletedParentCategory,
    minPrice,
    maxPrice,
    dynamicFilter,
  ]);

  // filter//
  const [filter, SetFilter] = useState(false);

  const [appSettings, setAppSettings] = useState({});

  useEffect(() => {
    setAppSettings(getAppSettings());
  }, []);

  //filter//
  return (
    <div>
      <section className="bredcum-sec">
        <Container>
          <Breadcrumb>
            {defaultData.filter_id != "" ? (
              <Breadcrumb.Item href="#">{selectedFilter.title}</Breadcrumb.Item>
            ) : (
              <li className="breadcrumb-item">
                <Link to="/search">{translate("all_category")}</Link>
              </li>
            )}

            {defaultData.parent_category_id ? (
              <li className="breadcrumb-item">
                <Link to={`/search/${defaultData.parent_category_id}`}>
                  {parentCategory.title}
                </Link>
              </li>
            ) : null}

            {defaultData.category_id ? (
              <li className="breadcrumb-item">
                <Link
                  to={`/search/${defaultData.parent_category_id}}/${defaultData.category_id}`}
                >
                  {category.title}
                </Link>
              </li>
            ) : null}
          </Breadcrumb>
        </Container>
      </section>

      <section className="all-show-result">
        <Container>
          <div className="d-lg-none d-block">
            <span
              className="filter-mobile"
              onClick={() => SetFilter((prev) => !prev)}
            >
              {translate("filter")}
            </span>
          </div>

          <div className="all-result">
            <div className="result-showing d-flex flex-wrap align-items-center justify-content-between">
              <div className="result-heading">
                {defaultData.filter_id === "" &&
                  defaultData.category_id === "" &&
                  defaultData.parent_category_id === "" ? (
                  <>
                    <h2>{translate("all_category")}</h2>
                    <p>{translate("show_all_category")}</p>
                  </>
                ) : null}

                {defaultData.filter_id != "" ? (
                  <>
                    <h2>{selectedFilter.title}</h2>
                    <p>
                      {translate("show_res_from")} {selectedFilter.title}
                    </p>
                  </>
                ) : null}

                {defaultData.category_id ? (
                  <>
                    <h2>{category.title}</h2>
                    <p>
                      {translate("show_res_from")} {category.title}
                    </p>
                  </>
                ) : defaultData.parent_category_id ? (
                  <>
                    <h2>{parentCategory.title}</h2>
                    <p>
                      {translate("show_res_from")} {parentCategory.title}
                    </p>
                  </>
                ) : null}
              </div>

              <div className="grid-feature d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <Link to="#" onClick={() => setGrid(false)}>
                    <img
                      src={
                        grid
                          ? `${process.env.REACT_APP_BASE_URL}/assets/img/list-icon.svg`
                          : `${process.env.REACT_APP_BASE_URL}/assets/img/list-icon-active.svg`
                      }
                      alt=""
                    />
                  </Link>

                  <Link to="#" onClick={() => setGrid(true)}>
                    <img
                      src={
                        grid
                          ? `${process.env.REACT_APP_BASE_URL}/assets/img/list-icon2-active.svg`
                          : `${process.env.REACT_APP_BASE_URL}/assets/img/list-icon2.svg`
                      }
                      alt=""
                    />
                  </Link>
                </div>

                {defaultData.parent_category_id !== "" && (<Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                >
                  <option value="recent">Recent</option>
                  <option value="price-low-to-high">
                    {translate("price_l_h")}
                  </option>
                  <option value="price-high-to-low">
                    {translate("price_h_l")}
                  </option>
                </Form.Select>)}
              </div>
            </div>

            <Row className="m-0">
              <Col lg={3} className="pe-0">
                <div>
                  <div
                    className={
                      filter ? "openfilter filter-sidebar" : "filter-sidebar"
                    }
                  >
                    <span className="d-block d-lg-none filter-icon" onClick={() => { SetFilter(false) }}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                    <Form>
                      {/* <div className="filter-listing">
                            <h2>Delivery Day</h2>
                            <Form.Check type="radio" label="Get It by Tomorrow" />
                            <Form.Check type="radio" label="Get It in 2 Days" />
                            <Form.Check type="radio" label="Get It in 7 Days" />
                        </div> */}

                      {allCategory.length ? (
                        <div className="filter-listing">
                          <h2>{translate("all_category")}</h2>
                          {allCategory.map((value, index) => (
                            <Form.Check
                              key={index}
                              onClick={() => {
                                let new_c = [...seletedParentCategory];
                                if (new_c.indexOf(value.id) > -1) {
                                  new_c.splice(new_c.indexOf(value.id), 1);
                                } else {
                                  new_c.push(value.id);
                                }
                                setSeletedParentCategory(new_c);
                              }}
                              type="checkbox"
                              label={value.title}
                            />
                          ))}
                          {/* <a href="#" className="see-more">
                                        See more
                                        </a> */}
                        </div>
                      ) : null}

                      {subCategory.length ? (
                        <div className="filter-listing">
                          <h2>{translate("sub_category")}</h2>
                          {subCategory.map((value, index) => (
                            <Form.Check
                              key={index}
                              onClick={() => {
                                let new_c = [...seletedCategory];
                                if (new_c.indexOf(value.id) > -1) {
                                  new_c.splice(new_c.indexOf(value.id), 1);
                                } else {
                                  new_c.push(value.id);
                                }
                                setSeletedCategory(new_c);
                              }}
                              type="checkbox"
                              label={value.title}
                            />
                          ))}
                          {/* <a href="#" className="see-more">
                                        See more
                                        </a> */}
                        </div>
                      ) : null}

                      {dynamicCategory.map((value, index) => (
                        <div className="filter-listing" key={index}>
                          <h2>{value.title}</h2>

                          {value.type === "input" ? (
                            <div>
                              <input
                                type="number"
                                placeholder="Min"
                                style={{ width: "40%" }}
                                onChange={(e) => {
                                  let new_d_filter = [...dynamicFilter]; //;
                                  let tmp_find = false;
                                  for (let i in new_d_filter) {
                                    if (new_d_filter[i].key === value.key) {
                                      tmp_find = true;
                                      new_d_filter[i].min = e.target.value;
                                    }
                                  }
                                  if (!tmp_find) {
                                    new_d_filter.push({
                                      key: value.key,
                                      type: value.type,
                                      min: e.target.value,
                                      max: 0,
                                    });
                                  }
                                  setDynamicFilter(new_d_filter);
                                }}
                              />
                              <input
                                type="number"
                                placeholder="Max"
                                style={{ width: "40%", marginLeft: "16%" }}
                                onChange={(e) => {
                                  let new_d_filter = [...dynamicFilter]; //;
                                  let tmp_find = false;
                                  for (let i in new_d_filter) {
                                    if (new_d_filter[i].key === value.key) {
                                      tmp_find = true;
                                      new_d_filter[i].max = e.target.value;
                                    }
                                  }
                                  if (!tmp_find) {
                                    new_d_filter.push({
                                      key: value.key,
                                      type: value.type,
                                      min: 0,
                                      max: e.target.value,
                                    });
                                  }
                                  setDynamicFilter(new_d_filter);
                                }}
                              />
                            </div>
                          ) : (
                            value.value.map((valueA, indexA) => (
                              <Form.Check
                                key={indexA}
                                onClick={(e) => {
                                  let new_d_filter = [...dynamicFilter]; //;
                                  let tmp_find = false;
                                  for (let i in new_d_filter) {
                                    if (new_d_filter[i].key === value.key) {
                                      tmp_find = true;
                                      if (
                                        new_d_filter[i].value.indexOf(
                                          valueA.key
                                        ) > -1
                                      ) {
                                        new_d_filter[i].value.splice(
                                          new_d_filter[i].value.indexOf(
                                            valueA.key
                                          ),
                                          1
                                        );
                                      } else {
                                        new_d_filter[i].value.push(valueA.key);
                                      }
                                    }
                                    if (!new_d_filter[i].value.length) {
                                      new_d_filter.splice(i, 1);
                                    }
                                  }
                                  if (!tmp_find) {
                                    new_d_filter.push({
                                      key: value.key,
                                      type: value.type,
                                      value: [valueA.key],
                                    });
                                  }
                                  setDynamicFilter(new_d_filter);
                                }}
                                type="checkbox"
                                label={valueA.title}
                              />
                            ))
                          )}
                          {/* <a href="#" className="see-more">
                                        See more
                                        </a> */}
                        </div>
                      ))}

                      <div className="filter-listing">
                        <h2>{translate("price")}</h2>

                        <Form.Check
                          type="radio"
                          onClick={(e) => {
                            setMinPrice(0);
                            setMaxPrice(100);
                          }}
                          name="price"
                          label={`${translate("under")} ${appSettings.currency_symbol
                            }100`}
                        />

                        <Form.Check
                          onClick={(e) => {
                            setMinPrice(100);
                            setMaxPrice(200);
                          }}
                          type="radio"
                          name="price"
                          label={` ${appSettings.currency_symbol}100 - ${appSettings.currency_symbol}200`}
                        />

                        <Form.Check
                          onClick={(e) => {
                            setMinPrice(200);
                            setMaxPrice(500);
                          }}
                          type="radio"
                          name="price"
                          label={`${appSettings.currency_symbol}200 - ${appSettings.currency_symbol}500`}
                        />

                        <Form.Check
                          onClick={(e) => {
                            setMinPrice(500);
                            setMaxPrice(1000);
                          }}
                          type="radio"
                          name="price"
                          label={`${appSettings.currency_symbol}500 - ${appSettings.currency_symbol}1,000`}
                        />

                        <Form.Check
                          onClick={(e) => {
                            setMinPrice(1000);
                            setMaxPrice(0);
                          }}
                          type="radio"
                          name="price"
                          label={`${translate("over")} ${appSettings.currency_symbol
                            }1,000`}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col lg={9} className="">
                <InfiniteScroll
                  dataLength={products.length}
                  next={() => {
                    loadProducts(false);
                  }}
                  hasMore={hasMore()}
                  //hasMore={false}
                  loader={<h4>{translate("loading")}...</h4>}
                >
                  {![...products].length ? (
                    <h5 style={{ textAlign: "center", padding: "200px 0" }}>
                      {translate("no_rec")}
                    </h5>
                  ) : grid ? (
                    <section className="history-sec grid-product-list py-3">
                      <Productlist products={[...products]} />
                    </section>
                  ) : (
                    <ProductListing products={products} />
                  )}
                </InfiniteScroll>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CategoryGrid;
